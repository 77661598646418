export const remoteJobTestQuestionsEng = [
  {
    id: 1,
    question:
      "Is your monitor sufficiently large, comfortable, and properly positioned at the correct distance (60 ± 15 cm), adjustable in height, with a stable image and no flickering or shaking?",
    info: [
      {
        text: `
      • The monitor should be sufficiently large (according to the tasks at hand),
      free from technical defects, and provide a high-quality image (without flickering or image jitter).
      `,
      },
      {
        text: `
      • The monitor should be easily rotatable, tiltable, with the ability to adjust
      its height, viewing angle, and distance to the eyes (recommended distance is 60 ± 15 cm).
      `,
      },
      {
        text: `
      • The top edge of the monitor should be at eye level or slightly lower. The screen should be directly in front of the user.
      It's preferable if it's possible to adjust the brightness and contrast of the image.
      `,
      },
    ],
  },
  {
    id: 2,
    question: "Do your windows have light-reflecting blinds or curtains?",
    info: [
      {
        text: `
      • Light should flow through the windows in a way that doesn't create reflections on the computer monitor or other surfaces.
      If this happens, it is recommended to equip the windows with light-reflecting blinds or curtains.
      Blinds will also be useful in case direct sunlight through the window is too bright.
      `,
      },
    ],
  },
  {
    id: 3,
    question:
      "Is your workplace sufficiently spacious and comfortable, allowing for unrestricted movement?",
    info: [
      {
        text: `
        The workspace should be sufficiently spacious to accommodate an appropriately sized desk and chair,
        allowing for easy movement and mobility.
      `,
      },
      {
        text: `
It's important that it's possible to sit directly in front of the monitor at the work desk, and the workspace
should be large enough to accommodate all the necessary items.
      `,
        severity: "info",
      },
    ],
  },
  {
    id: 4,
    question:
      "Is there an appropriate temperature and sufficient humidity in your workplace?",
    info: [
      {
        text: `
      The workplace should provide a microclimate suitable for the workload, including temperature, relative humidity, and air exchange rate.
      When working with a computer, these parameters are as follows: `,
      },
      {
        text: `• Temperature in summer: 20-28 °C, in winter: 19-25 °C;`,
      },
      {
        text: `• Relative humidity: 30-70%;`,
      },
      { text: `• Air movement speed: 0.05-0.15 m/s` },
      {
        text: `If there is no general ventilation system or forced air exchange in the room, regular room ventilation should be performed.`,
        severity: "info",
      },
    ],
  },
  {
    id: 5,
    question:
      "If you are using a laptop, is it comfortably placed, and is a full-sized keyboard and monitor connected?",
    info: [
      {
        text: `
      If you use a laptop for extended periods while working from home, it's important to remember that its use
      can pose additional risks, especially if it is very compact (with a screen size of 14" or smaller). A
      smaller screen can increase the risk of eye strain and shoulder/neck tension. A smaller keyboard can also
      lead to uncomfortable working postures.
      `,
      },
      {
        text: `
      If you use a laptop for extended periods (more than two hours a day), it is recommended to connect it to a
      larger-sized monitor, keyboard, and mouse (for example, using a docking station or expansion base).
      `,
      },
    ],
  },
  {
    id: 6,
    question:
      "Is your keyboard non-reflective with clearly visible symbols, placed in front of the monitor? Is the mouse comfortable to use?",
    info: [
      {
        text: `
      The keyboard should be easy to use, sufficiently large, matte with clear and understandable symbols, stable, and easy to move
      around the desk (with a sufficiently long cable).
      `,
      },
      {
        text: `
      There should be sufficient space for wrist support in front of the keyboard (at least 10 cm). It is recommended to use
      a keyboard that is positioned at a slight angle to the desk surface and is as slim as possible. The mouse should be as
      large as possible, placed close to the keyboard, and easy to move. It's advisable to choose a vertical mouse or try out
       multiple models before making a choice.
      `,
      },
    ],
  },
  {
    id: 7,
    question:
      "Is your desk sufficiently large (minimum 800 x 1200 mm) and tall (minimum 720 mm), with rounded corners, stable, and with enough space beneath it?",
    info: [
      {
        text: `
      The desk should be sufficiently large (minimum size is 800 x 1200 mm, recommended size is 1000 x 1600 mm), with a
      height not lower than 720 mm. It's advisable to use desks with adjustable height or so-called "standing desks"
      that allow both sitting and standing positions.
      `,
      },
      {
        text: `
      The desk should have rounded corners, be stable, and provide ample legroom underneath.
      `,
      },
    ],
  },
  {
    id: 8,
    question:
      "Is there additional local lighting available at your workplace that doesn't cause glare or reflections?",
    info: [
      {
        text: `
      
If necessary, the workplace should be equipped with local lighting, preferably with adjustable intensity.
It's important that it doesn't create reflections, glare, or excessively bright lighting that causes contrast.
      `,
      },
    ],
  },
  {
    id: 9,
    question:
      "Is there sufficient lighting in the room, and is it in good working condition (all bulbs are functioning, and there is no flickering etc.)?",
    info: [
      {
        text: `
      Natural lighting in the room is not sufficient, and it's necessary to provide adequate general (artificial) lighting. When performing
      tasks such as reading, printing, and data processing, the lighting intensity should be approximately 500 lux.
      `,
      },
      {
        text: `
      Light sources should be positioned outside the line of sight, should not cause glare, and should not create reflections
       on the monitor. The lighting should be in good working order (all bulbs should be operational, and
        there should be no flickering or jittering in the light). Light fixtures should be regularly cleaned.
      `,
      },
    ],
  },
  {
    id: 10,
    question:
      "Is your office chair suitable for computer work? Is it stable, swiveling, with adjustable height and depth, and can you adjust the height of the backrest and armrests?",
    info: [
      {
        text: `
      The chair should be stable, easily swiveling around its axis, and movable (preferably with five wheels).
      It's important to choose a chair that can be adjusted for at least seat and backrest height, seat depth,
      and tilt. Additionally, it should have adjustable armrests (height and preferably width).
      `,
      },
      {
        text: `
      The backrest and seat should be padded and covered with a non-slip, breathable material that does not generate static electricity.
      `,
      },
    ],
  },
  {
    id: 11,
    question:
      "Is the floor at your workplace smooth, without steps, thresholds, improperly placed cables, or other hazardous objects?",
    info: [
      {
        text: `
      Most accidents in home workplaces are related to tripping and falling. Therefore, it's important for floors to be
      even and not slippery. Cables, wires, and other objects should be arranged and organized in a way that they do not
      pose a tripping hazard. Attention should also be given to thresholds, steps, and other potentially dangerous areas.
      `,
        severity: "warning",
      },
    ],
  },
  {
    id: 12,
    question:
      "Do you have access to suitable and up-to-date software for your work that allows you to perform your tasks effectively?",
    info: [
      {
        text: `
      If you are working remotely with a personal computer, it's important to ensure that it is equipped with suitable
      and up-to-date software that allows you to work effectively.
      `,
      },
      {
        text: `
      It's important that even outside the office, you have access to all the necessary resources, including servers, databases, etc.
      `,
      },
    ],
  },
  {
    id: 13,
    question:
      "Is it possible for you to complete your work tasks within an appropriate timeframe without being disrupted by family members?",
    info: [
      {
        text: `
      One of the most significant risks of remote work is the inability to separate work tasks from personal life, which can lead to
      increased psychological and emotional stress and have a negative impact on family and personal life.
      `,
      },
      {
        text: `
      It is advisable to strictly separate the time dedicated to work tasks from your private and family life.
      `,
      },
    ],
  },
  {
    id: 14,
    question:
      "Are your equipment, cables, lamps, etc in working order without visible defects?",
    info: [
      {
        text: `
      For any electrical device you use, such as a computer or lighting fixtures, it should be in working order.
      Make sure that there are no visible operational issues like smoking, sparking, or other anomalies when you turn them on or use them.
      `,
      },
      {
        text: `
      In such cases, immediately stop working and seek assistance!
      `,
        severity: "critical",
      },
    ],
  },
  {
    id: 15,
    question:
      "Is communication with your immediate supervisor and colleagues sufficient and technically straightforward?",
    info: [
      {
        text: `
      When working remotely, effective communication is especially important, as its absence can have a
      negative impact on both the individual employee and the work collective as a whole.
      Communication with your immediate supervisor and colleagues should also be technically supported
      (for example, using Skype or similar programs).
      `,
      },
      {
        text: `
      The employer should cover the expenses related to such communication.
      `,
        severity: "info",
      },
    ],
  },

  {
    id: 16,
    question:
      "Are the tasks to be performed in remote work clearly formulated and understandable, achievable within the specified timeframes?",
    info: [
      {
        text: `
      In remote work, it is especially important for work tasks to be clearly formulated and understandable.
      `,
      },
      {
        text: `
      It's important that they can be completed within specified work hours and that there are clear requirements for work reports and deadlines.
      `,
      },
    ],
  },
];
