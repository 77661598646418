import axios from "axios";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";

export const getNotifications = async () => {
  const res = await axios
    .get(apiRoutes.getNotifications, apiHeaders())
    .then((res) => ({ ...res.data }))
    .catch((err) => undefined);
  return res;
};

// get all protocols which still don't have some sort of a completion, either are draft or have no answers
export const getProtocolCompletionNotifications = (protocols, userId) => {
  if (!protocols) {
    return;
  }

  if (userId) {
    return protocols.filter((pc) =>
      pc.descriptions.find(
        (dc) =>
          dc.answer_date === "" &&
          dc.is_confirmed == 0 &&
          dc.assigned_persons.includes(userId)
      )
    );
  }

  return protocols.filter(
    (pc) =>
      pc.is_draft == true ||
      pc.descriptions.find(
        (dc) => dc.answer_date === "" && dc.is_confirmed == 0
      ) ||
      pc.descriptions.find(
        (dc) => dc.is_confirmed == 0 && dc.answer_date !== ""
      )
  );
};

// get all protocols that need to be edited
export const getProtocolEditNotifications = (protocols, userId) => {
  return protocols.filter((pc) =>
    pc.descriptions.find(
      (dc) => dc.needs_edit == true && dc.assigned_persons.includes(userId)
    )
  );
};
