import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { AlertContext } from "src/contexts/alert-context";
/**
 * @param {string} severity - {"error" | "success" | "warning" | "info"} The severity of the alert.
 * @param {string} message - Message to be displayed by the alert.
 * @param {string | number} duration - Duration of the alert in seconds. Can also be "infinity", then it will not close.
 * @param {"filled" | "outlined"} variant - style variant of the alert.
 * @param {function} onClose - Additional function to the onClose. Alert object is passed as a parameter
 */
export const useAlerts = () => {
  return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const createAlert = (
    severity = "error",
    message = "Notikusi neparedzēta kļūda.",
    duration = 2,
    variant = "filled",
    onClose = () => {}
  ) => {
    const newAlert = {
      id: new Date().getTime() * Math.random(),
      severity,
      message,
      duration,
      variant,
      onClose,
    };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]);
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  const removeAllAlerts = () => {
    setAlerts([]);
  };

  useEffect(() => {
    // Clear alerts when the component unmounts (page change or refresh)
    setAlerts([]);
  }, []);

  return (
    <AlertContext.Provider
      value={{ alerts, createAlert, removeAlert, removeAllAlerts }}
    >
      {children}
    </AlertContext.Provider>
  );
};
