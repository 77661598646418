import { useCallback, useState } from "react";

const storageNavKey = "defaultNavState";

export const useNav = () => {
  const [open, setOpen] = useState(
    localStorage.getItem(storageNavKey)
      ? localStorage.getItem(storageNavKey) == "open"
      : true
  );

  const handleOpen = useCallback(() => {
    localStorage.setItem(storageNavKey, "open");
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    localStorage.setItem(storageNavKey, "closed");
    setOpen(false);
  }, []);

  return {
    handleOpen,
    handleClose,
    open,
  };
};
