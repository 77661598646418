export const Logo = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width ?? "24"}
      height={height ?? "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_1_2"
        style={{ maskType: "luminance" }}
        width="16"
        height="19"
        x="0"
        y="6"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M10.36 6.639H5.308l-2.526 4.364-2.526 4.364 2.526 4.364 2.526 4.362v.002h10.107l-1.263-2.182-1.264-2.182H7.834L6.571 17.55l-1.263-2.181h-.001l1.264-2.183 1.263-2.182h5.053L14.15 8.82l1.264-2.182h-5.053z"
        ></path>
      </mask>
      <g mask="url(#mask0_1_2)">
        <path
          fill="url(#pattern0)"
          d="M0.199 6.426H15.545V24.326999999999998H0.199z"
        ></path>
        <mask
          id="mask1_1_2"
          style={{ maskType: "luminance" }}
          width="11"
          height="6"
          x="5"
          y="19"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M10.36 19.731H7.836L6.57 21.913l-1.263 2.182h10.106l-1.263-2.182-1.264-2.182h-2.526z"
          ></path>
        </mask>
        <g mask="url(#mask1_1_2)">
          <path
            fill="url(#pattern1)"
            d="M5.122 19.707H15.546V24.327H5.122z"
          ></path>
        </g>
        <mask
          id="mask2_1_2"
          style={{ maskType: "luminance" }}
          width="11"
          height="6"
          x="5"
          y="6"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M10.36 6.64H5.308L6.57 8.82l1.263 2.182h5.053L14.15 8.82l1.264-2.182H10.36z"
          ></path>
        </mask>
        <g mask="url(#mask2_1_2)">
          <path
            fill="url(#pattern2)"
            d="M5.122 6.426H15.546V11.046H5.122z"
          ></path>
        </g>
      </g>
      <mask
        id="mask3_1_2"
        style={{ maskType: "luminance" }}
        width="16"
        height="18"
        x="9"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M14.148.095H9.095l1.263 2.182 1.263 2.182h5.054l1.263 2.182L19.2 8.822h.001l-1.264 2.183-1.263 2.181v.001h-5.053l-1.264 2.182-1.263 2.182h10.107l2.526-4.365 2.527-4.364-2.527-4.363L19.201.097V.095h-5.054z"
        ></path>
      </mask>
      <g mask="url(#mask3_1_2)">
        <path
          fill="url(#pattern3)"
          d="M8.886 0.074H24.521V17.686H8.886z"
        ></path>
        <mask
          id="mask4_1_2"
          style={{ maskType: "luminance" }}
          width="11"
          height="5"
          x="9"
          y="13"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M14.149 13.187h-2.527l-1.264 2.182-1.263 2.182h10.107l-1.264-2.182-1.263-2.182h-2.526z"
          ></path>
        </mask>
        <g mask="url(#mask4_1_2)">
          <path
            fill="url(#pattern4)"
            d="M8.886 13.067H19.31V17.687H8.886z"
          ></path>
        </g>
        <mask
          id="mask5_1_2"
          style={{ maskType: "luminance" }}
          width="11"
          height="5"
          x="9"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M14.148.095H9.095l1.263 2.182 1.263 2.182h5.054l1.263-2.182L19.202.095h-5.054z"
          ></path>
        </mask>
        <g mask="url(#mask5_1_2)">
          <path
            fill="url(#pattern5)"
            d="M8.886 0.074H19.31V4.694H8.886z"
          ></path>
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01887 .01613)" xlinkHref="#image0_1_2"></use>
        </pattern>
        <pattern
          id="pattern1"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02778 .0625)" xlinkHref="#image1_1_2"></use>
        </pattern>
        <pattern
          id="pattern2"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02778 .0625)" xlinkHref="#image2_1_2"></use>
        </pattern>
        <pattern
          id="pattern3"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01852 .0164)" xlinkHref="#image3_1_2"></use>
        </pattern>
        <pattern
          id="pattern4"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02778 .0625)" xlinkHref="#image4_1_2"></use>
        </pattern>
        <pattern
          id="pattern5"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.02778 .0625)" xlinkHref="#image5_1_2"></use>
        </pattern>
        <image
          id="image0_1_2"
          width="53"
          height="62"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA+CAYAAACLIVOzAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABv0lEQVR4nO2a23KCMBRF/fTWC4riFUEQFAQFpe037sZaawcRwyAY5DycD1hz1mT2TtLYR18Io08E+wi7MMI2OMAP9vB3IbxtiI0fwPV2cLwtnI2Ptetj5Xiw1xs2LqyVi6XtwLTWMKwVjKWNhWlDNyxoxhLawsRcN6FqBmbzBRsdU1XHZKZhPJ2zUTGaqBiOZ1BGUzYTDIZj9JXTyIMRmyG6sgKpN2DTR6cr/0xbktHq9Nh00WxLeG9JeGu20ThDhYcPIcHOUL2+cgVGUGlQjwQ6QSUDxaH+A3FDVXFLpF8Z+l2groGSoF5Ov6QjnfTLrd8v1G2gOFQcKBnqApQBSuQtkX759bsNFQe6DXUBSoN6Mf0o0BYL9Qd0F0rlgjoCCZ8oqE+Jol+loCqlXxW3RPrl1S890FKfIv0eph9voH1KnxLxSKc+lXdTmQItA7oXaI9AtQm0pB9BPV0/6lME9cAbWupTHFsi/YoKtCX2KXGPdOpT5QVa3geCWvUp0q/oBwI+KOpTpF+9ocoMtKX1KfG2RPoVG2iFeh4Vb0ukX/qmsgbaIxBfoK15nyL9yv7xUtiHK9JPODCCEiHQZq/z3yqXXDPKOwfxAAAAAElFTkSuQmCC"
        ></image>
        <image
          id="image1_1_2"
          width="36"
          height="16"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAQCAYAAAB+690jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABC0lEQVR4nO3Ux3LCMBSFYR6dBHC35W659wIZnvHEknCYZBOSARbAQvtv/ntGq/X7Fm8bCZudPD8FW0mFpGjz06FoBlTdhGYQ6CaBYdkwiQvL9kAcH7YbwPXD+UXwwxhBFCOkKaI4A01zxGmBNK+QFRXyskFRtSibDnU7oOlGtMOEbthjmA4Y9x+YDkes/gZyOIg4AuR4IQd5ARUgmnAQTQQoycoTqOagsv4HaCcLkKwaJ5DFQawOA4k6Hq8jQBEHsToMxOvMIFbnKqBLCv12sqXQVU62FDqDvhdaTnYudOOT3WPUVdMLUD9yUD++Rn2nUS+FbvoPPdyov872GvWFo2Z1nmPUP0/2NKP+BAEkMecSlN75AAAAAElFTkSuQmCC"
        ></image>
        <image
          id="image2_1_2"
          width="36"
          height="16"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAQCAYAAAB+690jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABBUlEQVR4nO3S2U7CUBSFYR5dBTrP42l7OtdSIDzj8pyNDRqjEWOCmF7s+y//XqvpcMJuf8Q4HTCMe/TjhG7Yoe1H1N2AqulR1h141aIoG+S8BitKsJwjYQViliNKMoQxQxAl8MMErh+JC2G7ASzHh2m7MCwXumlDMywominOwEbRsd5q4lQ8rhU8PG2w+grUdM+o255QEsSrMygrKgKlGSdQnM6glEBeEBPI8UICWY73CnIIpOoWgbbqlSBZSIKuLTSDPhZy3hX6EejysoZAVEeAZB0CiToSJOu8fZms8+sv+6zQ3b1sGfVdjfqMWUZ941HPoO+M+vK2P1RoGfX8srnQvx71C/0ZMpP/wsVxAAAAAElFTkSuQmCC"
        ></image>
        <image
          id="image3_1_2"
          width="54"
          height="61"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA9CAYAAADmgpoeAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACWUlEQVR4nN3X7VoTMRAF4HMv0t6woAgWBBWxYoGq4AeiXtV+oet0k7jTsFtLm6TJ/Dj/533OPLNZ3H0a1E2uWK5ZPrN8Yfk6qH+bfGO5Gbb5znLL8oPl57D+Y/LLXXD3UcOEAQk2rCXiUH2gwRpcIOBND/DWLbCBmQQFem4Q1ZRQ0xYnBdjChOFQXRLkkuGEAFFdaJgwoIKZLA0cRA9Edc5gNnDaDUzh86BgJiu1F+d6oprQkBOGEwJE+V7DhAEbmEQcyjMFkwYk2Fbd4IQBUb6bweThFMxEEBDlmDBjhhMCRPlWw4QBFUwgDuUpDX6qcTZw3AM86wFOeoAbeH+2MI4T0CDKNwzmq72VgRbuAUAUBJtldeD9BmNYTxQnBDtRuE5gouvZwhbh1mjPPXCwFBDFa8KYWMCUG0TxisE4LvH1VDAb19FemRgQxUsN4xHQoIKZrIOL7PWC4pgGPe7BJdxeCxMGRHFEgx314BatZ+QHBsULDVsVGGl7yAk2yxww9Hp6ODDIDxXsH3Cd9iJ6vSiYSVd7vtbT8+cB+QFhDhjOc4OhDkwLC4ELeGCQj2j4EcP5Anr+PbLXE/lzDQsFDHRgFMwkJNDzgZmHRdCeq+OCfJ8G3LdwmwQ6OjDI92iwPY3bNNDheiIzMI6zgaMe4GE3MIb3J7JnW/UcbpPtOQQqmEksQAcHBtkugXYZzgamtJ4MSLBH9X9xywLXac/x7xGypwSb5aHAyNbTBiJ7omEpAhe0p2AmqQI7GkS2w2CCcApmIgGocci2GUwQENljGnZbRxBQwQTi/gJDkexCtYdUfgAAAABJRU5ErkJggg=="
        ></image>
        <image
          id="image4_1_2"
          width="36"
          height="16"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAQCAYAAAB+690jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA/ElEQVR4nO2V2Q7BUBRF/Yvb/jBipqbW3KLmKX7KWI6tiJvSUg3SxMPKfV1ZZyc3sAwFaRUGERAFMUarOKN1EqRAGmSBxGiTAwVQBCWgMNqWQQVUQR00BNo1gQZaoC2QoYtkdPH2QB8MBdqPwFik/RTMwFykw0KkwL1Q8CyU4IQyZylTKM9JyZzUSah2kroIqRcpCO06go0QmDwVYo+FJE7oWki2FKpxhdRbIUchT4UkSyHr2ZwK6W8XshGyK6R8vJBfR13yw6jrbk/mtZB11PJXRu2y0M9Hnf+P2kUhp1Fr3xj1i3+ZKeU06oG/R+39L7s727VQ91bIsCl0BNs1P8EP31v5AAAAAElFTkSuQmCC"
        ></image>
        <image
          id="image5_1_2"
          width="36"
          height="16"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAQCAYAAAB+690jAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA5klEQVR4nO3UVw6CQBSFYfcC44btvYOgotgbGjclCur1WIijUSKxJAYfvvc/Z24msFsEaTuHGRgwgTGjzQiG0IcedINktxnZOrSgychqQB1qIlkKVEVay1CBMpSgINIqDznIQBpSIpkJiENMIDMCYYGWIYECrkGDS5DdYddBmhOEEJULkrig4qtB00MQcw86r3MMctZRTzGWzAWVuKBDTJYLSjpBgseF3J7s0ULyOxcynlhI5xZqcAspNwvdBt1dyOsNfeuoo3456vXHj3rw4lG/5x/61aPW/kf9P2o/HbXELVT0dtR737VARklPJIoAAAAASUVORK5CYII="
        ></image>
      </defs>
    </svg>
  );
};
