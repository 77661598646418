import { Avatar, Chip, Divider, Stack } from "@mui/material";

export const NotificationHint = ({ count, title }) => {
  if (!count || count === "" || count === 0) {
    return "";
  }
  return (
    <>
      <Divider />
      <Stack direction={"column"} sx={{ px: 2, py: 1 }}>
        <Chip
          avatar={
            <Avatar variant="circular" sx={{ backgroundColor: "white" }}>
              {count}
            </Avatar>
          }
          label={title}
          sx={{
            borderRadius: 1,
            backgroundColor: "primary.main",
            width: "100%",
            justifyContent: "start",
          }}
          variant="filled"
        />
      </Stack>
      <Divider />
    </>
  );
};
