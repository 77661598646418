import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));

// Academy
const AcademyEditPage = lazy(() => import("src/pages/dashboard/academy/edit"));
const AcademyAddPage = lazy(() => import("src/pages/dashboard/academy/add"));
const AcademyListPage = lazy(() => import("src/pages/dashboard/academy/list"));
const AcademyDetailsPage = lazy(() =>
  import("src/pages/dashboard/academy/course")
);

// Academy Tests
const AcademyTestsAddPage = lazy(() =>
  import("src/pages/dashboard/academyTests/add")
);

// Account
const AccountPage = lazy(() => import("src/pages/dashboard/account/account"));

// Archive
const Archive = lazy(() => import("src/pages/dashboard/archive/list"));

// Customers
const CustomerListPage = lazy(() =>
  import("src/pages/dashboard/customers/list")
);
const CustomerDetailPage = lazy(() =>
  import("src/pages/dashboard/customers/detail")
);
const CustomerEditPage = lazy(() =>
  import("src/pages/dashboard/customers/edit")
);
const CustomerAddPage = lazy(() => import("src/pages/dashboard/customers/add"));

// Occupations
const OccupationsListPage = lazy(() =>
  import("src/pages/dashboard/occupations/list")
);
const OccupationsDetailPage = lazy(() =>
  import("src/pages/dashboard/occupations/detail")
);
const OccupationsEditPage = lazy(() =>
  import("src/pages/dashboard/occupations/edit")
);
const OccupationsAddPage = lazy(() =>
  import("src/pages/dashboard/occupations/add")
);

// FileManager
const FileManager = lazy(() => import("src/pages/dashboard/fileManager/list"));

// Branches
const BranchesListPage = lazy(() =>
  import("src/pages/dashboard/branches/list")
);

// Branches
const BranchGraphicsPage = lazy(() =>
  import("src/pages/dashboard/branchGraphics/list")
);

// Instructions
const InstructionsListPage = lazy(() =>
  import("src/pages/dashboard/instructions/list")
);
const InstructionsDetailPage = lazy(() =>
  import("src/pages/dashboard/instructions/detail")
);
const InstructionsEditPage = lazy(() =>
  import("src/pages/dashboard/instructions/edit")
);
const InstructionsAddPage = lazy(() =>
  import("src/pages/dashboard/instructions/add")
);

// Risks
const RisksListPage = lazy(() => import("src/pages/dashboard/risks/list"));
const RisksEditPage = lazy(() => import("src/pages/dashboard/risks/edit"));

// Medical Certificates
const MedicalCertificatesListPage = lazy(() =>
  import("src/pages/dashboard/medicalCertificates/list")
);

// Partner Companies
const PartnerCompaniesIndexPage = lazy(() =>
  import("src/pages/dashboard/partnerCompanies/index")
);
const PartnerCompaniesDetailPage = lazy(() =>
  import("src/pages/dashboard/partnerCompanies/detail")
);
const PartnerCompaniesEditPage = lazy(() =>
  import("src/pages/dashboard/partnerCompanies/edit")
);
const PartnerCompaniesAddPage = lazy(() =>
  import("src/pages/dashboard/partnerCompanies/add")
);

// Protocols
const ProtocolsListPage = lazy(() =>
  import("src/pages/dashboard/protocols/list")
);
// Protocols
const ProtocolsBranchPage = lazy(() =>
  import("src/pages/dashboard/protocols/branch-list")
);
const ProtocolsDetailPage = lazy(() =>
  import("src/pages/dashboard/protocols/detail")
);
const ProtocolsEditPage = lazy(() =>
  import("src/pages/dashboard/protocols/edit")
);
const ProtocolsAddPage = lazy(() =>
  import("src/pages/dashboard/protocols/add")
);

// Equipment
const EquipmentIndexPage = lazy(() =>
  import("src/pages/dashboard/equipment/list")
);
const EquipmentListPage = lazy(() =>
  import("src/pages/dashboard/equipment/equipment-list")
);
const EquipmentDetailPage = lazy(() =>
  import("src/pages/dashboard/equipment/detail")
);
const EquipmentEditPage = lazy(() =>
  import("src/pages/dashboard/equipment/edit")
);
const EquipmentAddPage = lazy(() =>
  import("src/pages/dashboard/equipment/add")
);

// Protocols answers
const ProtocolsAnswersListPage = lazy(() =>
  import("src/pages/dashboard/protocolAnswers/list")
);
const ProtocolsAnswersEditPage = lazy(() =>
  import("src/pages/dashboard/protocolAnswers/edit")
);

// Plans
const PlansListPage = lazy(() => import("src/pages/dashboard/plans/list"));
const PlansAddPage = lazy(() => import("src/pages/dashboard/plans/add"));
const PlansEditPage = lazy(() => import("src/pages/dashboard/plans/edit"));

// Remote job test
const RemoteJobTestAddPage = lazy(() =>
  import("src/pages/dashboard/remoteJobTest/index")
);

const LargeNavigation = lazy(() =>
  import("src/pages/dashboard/largeNavigation")
);

// User academy index
const AcademyUserIndex = lazy(() =>
  import("src/pages/dashboard/academyUsers/index")
);

// User instructions
const AcademyUserInstructionListPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/userInstructions/index")
);
const AcademyUserInstructionPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/userInstructions/instruction")
);

// User risk assesment
const AcademyUserRiskAssesmentPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/userInstructions/riskAssesment")
);

// User courses
const AcademyUserCourseListPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/userCourses/index")
);
const AcademyUserCoursePage = lazy(() =>
  import("src/pages/dashboard/academyUsers/userCourses/course")
);

// Academy partner documents
const AcademyPartnerDocumentListPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/partnerDocuments/index")
);
const AcademyPartnerDocumentPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/partnerDocuments/document")
);

// User tests
const AcademyUserTestPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/tests/index")
);

// User test result
const AcademyUserResultPage = lazy(() =>
  import("src/pages/dashboard/academyUsers/results/index")
);

const Lesson = lazy(() =>
  import("src/pages/dashboard/academyUsers/userInstructions/instruction")
);

// User signed files
const UserSignedFilesIndexPage = lazy(() =>
  import("src/pages/dashboard/userSignedFiles/index")
);

// Accidents
const AccidentsIndexPage = lazy(() =>
  import("src/pages/dashboard/accidents/list")
);
const AddRegisterPage = lazy(() =>
  import("src/pages/dashboard/accidents/components/registers/add-register")
);
const EditRegisterPage = lazy(() =>
  import("src/pages/dashboard/accidents/components/registers/edit-register")
);
const AddAccidentProtocolPage = lazy(() =>
  import("src/pages/dashboard/accidents/components/protocols/add-protocol")
);
const EditAccidentProtocolPage = lazy(() =>
  import("src/pages/dashboard/accidents/components/protocols/edit-protocol")
);

// Settings
const SettingsPage = lazy(() => import("src/pages/dashboard/settings/index"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "academy",
        children: [
          {
            index: true,
            element: <AcademyListPage />,
          },
          {
            path: "courses",
            children: [
              {
                path: "add",
                element: <AcademyAddPage />,
              },
              {
                path: ":courseId",
                element: <AcademyDetailsPage />,
              },
              {
                path: "edit/:courseId",
                element: <AcademyEditPage />,
              },
            ],
          },
          {
            path: "tests",
            children: [
              {
                path: "add/:parentId",
                element: <AcademyTestsAddPage />,
              },
            ],
          },
        ],
      },
      {
        path: "academyUsers",
        children: [
          {
            index: true,
            element: <AcademyUserIndex />,
          },
          {
            path: "instructions",
            children: [
              {
                index: true,
                element: <AcademyUserInstructionListPage />,
              },
              {
                path: ":id",
                element: <AcademyUserInstructionPage />,
              },
              {
                path: "riskAssesment/:id",
                element: <AcademyUserRiskAssesmentPage />,
              },
            ],
          },

          {
            path: "courses",
            children: [
              {
                index: true,
                element: <AcademyUserCourseListPage />,
              },
              {
                path: ":id",
                element: <AcademyUserCoursePage />,
              },
            ],
          },

          {
            path: "partnerDocuments",
            children: [
              {
                index: true,
                element: <AcademyPartnerDocumentListPage />,
              },
              {
                path: ":id",
                element: <AcademyPartnerDocumentPage />,
              },
            ],
          },

          {
            path: "tests",
            children: [
              {
                path: ":id/type/:type/:typeId",
                element: <AcademyUserTestPage />,
              },
            ],
          },
          {
            path: "result",
            children: [
              {
                path: ":id/type/:type/:typeId",
                element: <AcademyUserResultPage />,
              },
            ],
          },
        ],
      },
      {
        path: "large-navigation",
        element: <LargeNavigation />,
      },
      {
        path: "accidents",
        children: [
          {
            index: true,
            element: <AccidentsIndexPage />,
          },
          {
            path: "add-order/:templateId",
            element: <AccidentsIndexPage />,
          },
          {
            path: "edit-order/:id",
            element: <AccidentsIndexPage />,
          },
          {
            path: "register/:templateId",
            element: <AddRegisterPage />,
          },
          {
            path: "edit-register/:id",
            element: <EditRegisterPage />,
          },
          {
            path: "add-protocol/:templateId",
            element: <AddAccidentProtocolPage />,
          },
          {
            path: "edit-protocol/:id",
            element: <EditAccidentProtocolPage />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: ":customerId",
            element: <CustomerDetailPage />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEditPage />,
          },
          {
            path: "add",
            element: <CustomerAddPage />,
          },
        ],
      },
      {
        path: "occupations",
        children: [
          {
            index: true,
            element: <OccupationsListPage />,
          },
          {
            path: ":occupationId",
            element: <OccupationsDetailPage />,
          },
          {
            path: "edit/:occupationId",
            element: <OccupationsEditPage />,
          },
          {
            path: "add",
            element: <OccupationsAddPage />,
          },
          {
            path: "add/:occupationId",
            element: <OccupationsAddPage />,
          },
        ],
      },
      {
        path: "files/:openedFolder",
        children: [
          {
            index: true,
            element: <FileManager />,
          },
        ],
      },
      {
        path: "archive",
        children: [
          {
            index: true,
            element: <Archive />,
          },
        ],
      },
      {
        path: "branches",
        element: <BranchesListPage />,
      },
      {
        path: "branchGraphics",
        element: <BranchGraphicsPage />,
      },
      {
        path: "instructions",
        children: [
          {
            index: true,
            element: <InstructionsListPage />,
          },
          {
            path: ":instructionId",
            element: <InstructionsDetailPage />,
          },
          {
            path: "edit/:occupationId",
            element: <InstructionsEditPage />,
          },
          {
            path: "add",
            element: <InstructionsAddPage />,
          },
        ],
      },
      {
        path: "risks",
        children: [
          {
            index: true,
            element: <RisksListPage />,
          },
          {
            path: "edit/:riskAssesmentId/:templateId",
            element: <RisksEditPage />,
          },
        ],
      },
      {
        path: "medicalCertificates",
        children: [
          {
            index: true,
            element: <MedicalCertificatesListPage />,
          },
        ],
      },
      {
        path: "partnerCompanies",
        children: [
          {
            index: true,
            element: <PartnerCompaniesIndexPage />,
          },
          {
            path: ":companyId/details",
            element: <PartnerCompaniesDetailPage />,
          },
          {
            path: ":companyId/edit",
            element: <PartnerCompaniesEditPage />,
          },
          {
            path: "add",
            element: <PartnerCompaniesAddPage />,
          },
        ],
      },
      {
        path: "protocols",
        children: [
          {
            index: true,
            element: <ProtocolsBranchPage />,
          },
          {
            path: "details/:protocolId",
            element: <ProtocolsDetailPage />,
          },
          {
            path: ":branchId",
            element: <ProtocolsListPage />,
          },
          {
            path: ":branchId/edit/:protocolId",
            element: <ProtocolsEditPage />,
          },
          {
            path: ":branchId/add/:protocolPageId",
            element: <ProtocolsAddPage />,
          },
        ],
      },
      {
        path: "protocols/equipment",
        children: [
          {
            index: true,
            element: <EquipmentIndexPage />,
          },
          {
            path: "details/:examId",
            element: <EquipmentDetailPage />,
          },
          {
            path: "list",
            element: <EquipmentListPage />,
          },
          {
            path: "edit/:examId",
            element: <EquipmentEditPage />,
          },
          {
            path: "add/:examId",
            element: <EquipmentAddPage />,
          },
        ],
      },
      {
        path: "protocolAnswers",
        children: [
          {
            index: true,
            element: <ProtocolsAnswersListPage />,
          },
          {
            path: "edit/:protocolId",
            element: <ProtocolsAnswersEditPage />,
          },
        ],
      },
      {
        path: "plans",
        children: [
          {
            index: true,
            element: <PlansListPage />,
          },
          {
            path: "edit/:planId",
            element: <PlansEditPage />,
          },
          {
            path: "add/:planId",
            element: <PlansAddPage />,
          },
        ],
      },
      {
        path: "remoteJobTest",
        children: [
          {
            index: true,
            element: <RemoteJobTestAddPage />,
          },
        ],
      },
      {
        path: "userSignedFiles",
        children: [
          {
            index: true,
            element: <UserSignedFilesIndexPage />,
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },
    ],
  },
];
