import { Box, CircularProgress, Stack } from "@mui/material";
import { HeroStripe } from "src/sections/home/hero-stripe";

export const LoadingLogin = () => {
  return (
    <Stack direction={"row"} spacing={2}>
      <Stack
        sx={{
          width: 270,
          height: 71,
          bgcolor: "white",
          margin: "auto",
          transform: "skewX(30deg)",
        }}
      >
        <Stack
          sx={{
            width: 210,
            margin: "auto",
            transform: "skewX(-30deg)",
          }}
          alignItems="center"
        >
          <CircularProgress sx={{ color: "primary.main" }} />
        </Stack>
      </Stack>
      <Box sx={{ transform: "skewX(30deg)" }}>
        <HeroStripe disabled />
      </Box>
    </Stack>
  );
};
