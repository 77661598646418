import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import {
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { usePathname } from "src/hooks/use-pathname";
import { useWindowScroll } from "src/hooks/use-window-scroll";
import { paths } from "src/paths";
import { TopNavItem } from "./top-nav-item";

const items = [
  {
    title: "Sazināties",
    path: paths.contact,
    highlight: false,
  },
];

const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  const pathname = usePathname();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [elevate, setElevate] = useState(false);
  const offset = 64;
  const delay = 100;

  const handleWindowScroll = useCallback(() => {
    if (window.scrollY > offset) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  }, []);

  useWindowScroll({
    handler: handleWindowScroll,
    delay,
  });

  return (
    <Box
      component="header"
      sx={{
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        pt: 2,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: "transparent",
          borderRadius: 2.5,
          boxShadow: "none",
          transition: (theme) =>
            theme.transitions.create("box-shadow, background-color", {
              easing: theme.transitions.easing.easeInOut,
              duration: 200,
            }),
          ...(elevate && {
            backgroundColor: (theme) =>
              alpha(theme.palette.background.paper, 0.9),
            boxShadow: 8,
          }),
        }}
      >
        <Stack direction="row" spacing={2} sx={{ height: TOP_NAV_HEIGHT }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            sx={{ flexGrow: 1 }}
          >
            <Stack
              alignItems="center"
              component={RouterLink}
              direction="row"
              display="inline-flex"
              href={paths.index}
              spacing={1}
              sx={{ textDecoration: "none" }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  height: 48,
                  width: 48,
                }}
              >
                <Logo width={48} height={48} />
              </Box>
              {mdUp && (
                <Box
                  sx={{
                    color: "text.secondary",
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                    fontSize: 28,
                    fontWeight: 800,
                    letterSpacing: "0.3px",
                    lineHeight: 2.5,
                    "& span": {
                      color: "primary.main",
                    },
                  }}
                >
                  Cerera
                </Box>
              )}
            </Stack>
          </Stack>
          {mdUp && (
            <Stack
              justifyContent={"center"}
              alignItems="flex-end"
              direction="column"
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Button variant="text">
                <Link
                  variant="h5"
                  fontSize="small"
                  color={"primary.main"}
                  href={paths.contact}
                  underline="none"
                  component={RouterLink}
                >
                  SAZINĀTIES
                </Link>
              </Button>
            </Stack>
          )}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ flexGrow: 1 }}
          ></Stack>
        </Stack>
      </Container>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
