import { Box, Stack } from "@mui/material";
import PhoneInput from "src/components/phone_input/phone_input";
import { HeroStripe } from "src/sections/home/hero-stripe";
import { SkewedButton } from "./register-button";
import { paths } from "src/paths";
import { sendVerificationCode } from "src/api/auth/login";

export const PhoneLoginField = ({
  setStatus,
  statuses,
  phone,
  setPhone,
  countryCode,
  setCountryCode,
}) => {
  const onSubmit = async () => {
    setStatus(statuses.loading);

    await sendVerificationCode(phone, countryCode)
      .then((res) => {
        setStatus(statuses.awaitingVerification);
      })
      .catch((err) => setStatus(undefined));
  };

  const checkIfEnterPressed = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <Stack spacing={2}>
          <Stack
            sx={{
              backgroundColor: "primary.main",
              width: "100%",
              height: 71,
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ transform: "skewX(-30deg)", margin: "0px 20px" }}>
              <PhoneInput
                onKeyDown={checkIfEnterPressed}
                label=""
                fullWidth
                setPhone={setPhone}
                phone={phone}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                variant="standard"
                inputProps={{
                  style: {
                    fontSize: "1rem",
                    paddingBottom: 7,
                    color: "black",
                  },
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <div style={{ width: "20%" }}>
          <HeroStripe onClick={onSubmit} small />
        </div>
      </Stack>

      <Stack
        direction={"row"}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <SkewedButton
          name={"Pieslēgties ar paroli"}
          onClick={() => setStatus(statuses.password)}
        />
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <SkewedButton
          name={"Sazināties"}
          onClick={() => (window.location.href = paths.contact)}
        />
      </Stack>
    </Stack>
  );
};
