import { Box } from '@mui/material';

export const BreadcrumbsSeparator = () => (
  <Box
    sx={{
      backgroundColor: 'neutral.500',
      borderRadius: '50%',
      height: 4,
      width: 4
    }}
  />
);
