import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";
import userData from "src/contexts/user-data-context";

import Lock01 from "@untitled-ui/icons-react/build/esm/Lock01";
import { HeroStripe } from "src/sections/home/hero-stripe";
import { SkewedButton } from "./register-button";
import { paths } from "src/paths";

export const TemporaryPasswordLogin = ({ setStatus, statuses }) => {
  const [password, setPassword] = useState("");
  const { setData } = useContext(userData);

  const onSubmit = async () => {
    setStatus(statuses.loading);

    await axios
      .post(
        apiRoutes.login,
        JSON.stringify({
          loginType: "password",
          password: password,
          phone: "",
          code: "",
        }),
        apiHeaders()
      )
      .then((res) => {
        setData({
          user: res.data.user,
          companies: res.data.companies,
          company: undefined,
        });
        sessionStorage.setItem("token", res.data.accessToken);
        sessionStorage.setItem("user_id", res.data.user.id);
        sessionStorage.setItem("firstTimeLogin", true);
        sessionStorage.setItem("usingPass", true);
      })
      .catch((err) => setStatus(statuses.login));
  };

  const checkIfEnterPressed = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <Stack spacing={2}>
          <Stack
            sx={{
              backgroundColor: "primary.main",
              width: "100%",
              height: 71,
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              sx={{
                transform: "skewX(-30deg)",
                margin: "0 61px",
                width: "90%",
              }}
            >
              <TextField
                onKeyDown={checkIfEnterPressed}
                fullWidth
                name="phone"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                variant="standard"
                inputProps={{
                  style: {
                    fontSize: "1rem",
                    paddingBottom: 7,
                    color: "black",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color={"text.primary"}>
                        <Lock01 fontSize={10} />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
        </Stack>
        <div style={{ width: "20%" }}>
          <HeroStripe onClick={onSubmit} small />
        </div>
      </Stack>

      <Stack
        direction={"row"}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <SkewedButton
          name={"Atcelt"}
          onClick={() => setStatus(statuses.login)}
        />
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          transform: "skewX(30deg)",
        }}
      >
        <SkewedButton
          name={"Sazināties"}
          onClick={() => (window.location.href = paths.contact)}
        />
      </Stack>
    </Stack>
  );
};
