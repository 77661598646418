import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";

export const AlertComponent = ({
  onClose,
  message,
  severity,
  variant,
  duration,
}) => {
  const [closing, setClosing] = useState(false);

  const springs = useSpring({
    from: { marginTop: -50 },
    to: { marginTop: closing ? -50 : 5, opacity: closing ? 0 : 1 },
    onRest: () => {
      if (closing) {
        onClose(); // Run onClose when the closing animation is complete
      }
    },
  });

  // Schedule the closing animation after 4 seconds
  useEffect(() => {
    if (duration !== "infinity") {
      const timer = setTimeout(
        () => {
          setClosing(true);
        },
        duration ? duration * 1000 : 2000
      );

      // Clear the timer if the component unmounts before the 4 seconds
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onClose]);

  return (
    <animated.div style={springs}>
      <Alert
        elevation={5}
        className="alert"
        id="alert"
        variant={variant}
        severity={severity}
        onClose={() => setClosing(true)}
      >
        {message}
      </Alert>
    </animated.div>
  );
};
