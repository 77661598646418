import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const { children } = props;

  return (
    <VerticalLayoutRoot>
      <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
    </VerticalLayoutRoot>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
};
