import { format, parseISO } from "date-fns";
import { lv } from "date-fns/locale";

export const getCurrentDateTime = () => {
  return format(new Date(), "yyyy-MM-dd HH:mm:ss");
};

export const convertISOToDateTime = (dateObj) => {
  return format(dateObj, "yyyy-MM-dd HH:mm:ss");
};

export const convertISODateToLocale = (date) => {
  try {
    return format(parseISO(date), "PPP", { locale: lv });
  } catch {
    return date;
  }
};

export const convertISODateToLocaleDetailed = (date) => {
  try {
    return format(parseISO(date), "PPP HH:mm:ss", { locale: lv });
  } catch {
    return date;
  }
};
