import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";
import userData from "src/contexts/user-data-context";

import Lock01 from "@untitled-ui/icons-react/build/esm/Lock01";
import { HeroStripe } from "src/sections/home/hero-stripe";

export const TemporaryPasswordLogin = ({ setStatus, statuses }) => {
  const [password, setPassword] = useState("");
  const { setData } = useContext(userData);

  const onSubmit = async () => {
    setStatus(statuses.loading);

    await axios
      .post(
        apiRoutes.login,
        JSON.stringify({
          loginType: "password",
          password: password,
          phone: "",
          code: "",
        }),
        apiHeaders()
      )
      .then((res) => {
        setData({
          user: res.data.user,
          companies: res.data.companies,
          company: undefined,
        });
        sessionStorage.setItem("token", res.data.accessToken);
        sessionStorage.setItem("user_id", res.data.user.id);
        sessionStorage.setItem("firstTimeLogin", true);
        sessionStorage.setItem("usingPass", true);
      })
      .catch((err) => {
        setPassword("");
        setStatus(statuses.password);
      });
  };

  const checkIfEnterPressed = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Stack direction={"row"} spacing={3} sx={{ transform: "skewX(30deg)" }}>
      <Stack
        sx={{
          width: 270,
          height: 71,
          bgcolor: "white",
          margin: "auto",
        }}
      >
        <Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              width: 270,
              height: 71,
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ transform: "skewX(-30deg)", margin: "0 20px" }}>
              <TextField
                onKeyDown={checkIfEnterPressed}
                fullWidth
                name="phone"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                variant="standard"
                inputProps={{
                  style: {
                    fontSize: "1rem",
                    paddingBottom: 7,
                    color: "black",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color={"text.primary"}>
                        <Lock01 fontSize={10} />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />

              <Stack
                direction="row"
                justifyContent={"space-between"}
                mt={1}
                mx={0}
                spacing={3}
                alignItems="center"
                alignContent={"center"}
              >
                <Typography color={"text.secondary"} variant={"caption"}>
                  Ievadiet jūsu paroli
                </Typography>
                <Typography
                  variant={"caption"}
                  color={"text.secondary"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => {
                    setStatus(statuses.loginChoice);
                    setPassword("");
                  }}
                >
                  Atcelt
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <HeroStripe onClick={onSubmit} />
    </Stack>
  );
};
