import { Box, Fade, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";
import userData from "src/contexts/user-data-context";

import { HeroStripe } from "src/sections/home/hero-stripe";
import { CodeVerificationField } from "./code-verification-field";
import { verifyUser } from "src/api/auth/login";

export const CodeVerification = ({
  setStatus,
  statuses,
  phone,
  setPhone,
  countryCode,
}) => {
  const { data, setData } = useContext(userData);
  const [focusIndex, setFocusIndex] = useState(0);

  const [textFields, setTextFields] = useState([
    { index: 0, value: "", ref: useRef(null) },
    { index: 1, value: "", ref: useRef(null) },
    { index: 2, value: "", ref: useRef(null) },
    { index: 3, value: "", ref: useRef(null) },
  ]);

  useEffect(() => {
    const textField = textFields[focusIndex]?.ref;
    if (textField && textField.current) {
      textField.current.focus();
    }

    // if user has filled all code fields, submit
    if (
      textFields.filter((el) => el.value.length !== 0).length ===
      textFields.length
    ) {
      onSubmit();
    }
  }, [focusIndex, textFields]);

  const fillInputField = (index, value) => {
    setTextFields(changeValue(index, value));
  };

  const handleInput = (event, currentIndex) => {
    const ALLOWED_CHARS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    const value = event.target.value;

    // if user copied over the code, or input it from messages, handle each character manually
    // and fill in the fields
    if (value.length > 1) {
      value
        .split("")
        .filter((char) => ALLOWED_CHARS.includes(char))
        .forEach((char, index) => fillInputField(currentIndex + index, char));

      // if code was input only partially, if it was filled fully, this is not necessary
      if (currentIndex < textFields.length) {
        setNextFocusIndex();
      }

      return;
    }

    if (value.length === 1 && !ALLOWED_CHARS.includes(value)) return;

    if (value.length === 1 && currentIndex < textFields.length) {
      // if current field is empty, and we added a value, add it and change to the next input field
      if (textFields[currentIndex].value.length === 0) {
        fillInputField(currentIndex, value);
        setNextFocusIndex();
      } else {
        // if current field is not empty, it means user has deleted value from the previoues input field,
        // so when user inputs something, we assign that value not on the same input field, but on the next empty
        // input field. without it, the user will have to click manually on the desired input field
        textFields.find((el) => {
          if (el.value.length === 0) {
            setTextFields(changeValue(el.index, value.substring(1)));
            setFocusIndex(el.index);
            return el;
          }
        });
      }
    }
  };

  const onDelete = (event, currentIndex) => {
    if (textFields[currentIndex].value === "") {
      if (currentIndex > 0) {
        setFocusIndex(currentIndex - 1);
      }
    } else {
      setTextFields(changeValue(currentIndex, ""));
    }
  };

  const setNextFocusIndex = () => {
    const emptyValue = textFields.find((el) => el.value.length === 0);
    if (emptyValue) {
      setFocusIndex(emptyValue.index);
    }
  };

  const changeValue = (index, value) => {
    const temp = [...textFields];
    temp[index].value = value;
    return temp;
  };

  const onSubmit = async () => {
    setStatus(statuses.loading);

    await verifyUser(
      textFields.map((el) => el.value).join(""),
      phone,
      countryCode,
      setData
    ).catch((err) => setStatus(undefined));
  };

  return (
    <Stack
      direction={"row"}
      sx={{
        width: "100%",
        height: 71,
        bgcolor: "white",
        transform: "skewX(30deg)",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          minWidth: 210,
          transform: "skewX(-30deg)",
        }}
      >
        <Stack width={"100%"}>
          <Stack direction={"row"}>
            {textFields.map((textFieldRef) => (
              <CodeVerificationField
                onKeyDown={onDelete}
                key={textFieldRef.index}
                textFieldRef={textFieldRef}
                setFocusIndex={setFocusIndex}
                handleInput={handleInput}
              />
            ))}
          </Stack>
          <Fade in={true} timeout={700}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              mx={3}
              mt={3}
              spacing={3}
              alignItems="center"
              alignContent={"center"}
            >
              <Typography color={"text.secondary"} variant={"h6"}>
                Ievadiet īsziņā saņemto kodu
              </Typography>
            </Stack>
          </Fade>
        </Stack>
      </Box>
    </Stack>
  );
};
