import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, SvgIcon } from "@mui/material";
import GraduationHat01Icon from "src/icons/untitled-ui/duocolor/graduation-hat-01";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LayoutAlt02Icon from "src/icons/untitled-ui/duocolor/layout-alt-02";
import Users03Icon from "src/icons/untitled-ui/duocolor/users-03";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import userData from "src/contexts/user-data-context";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import { KeyRounded } from "@mui/icons-material";

const useRights = () => {
  const { data } = useContext(userData);

  const notifications = useMemo(() => data?.notifications, [data]);

  const rights = {
    isAdmin: data?.user.is_cerera_admin == 1,
    hasRemoteJobTest: data?.user?.remote_job_ids,
    isPartner: data?.user?.user_has_partner_company,
  };

  const workers = data?.user.role_profile?.workers;
  const documents = data?.user.role_profile?.documents;
  const protocols = data?.user.role_profile?.protocols;
  const instructions = data?.user.role_profile?.instructions;
  const plans = data?.user.role_profile?.plans;
  const risk_assesments = data?.user.role_profile?.risk_assesments;

  return {
    user: data?.user,
    rights,
    notifications,
    workers,
    documents,
    protocols,
    instructions,
    plans,
    risk_assesments,
  };
};

export const useSections = () => {
  const { t } = useTranslation();
  const userRights = useRights();

  const canSeeWorkers =
    userRights.workers?.canSeeAllWorkers?.value ||
    userRights.workers?.canSeeBranchWorkers?.value ||
    userRights.rights.isAdmin;

  const canSeeDocuments =
    userRights.documents?.canSeeAllDocuments?.value ||
    userRights.rights.isAdmin;

  const canAnswerProtocols =
    userRights.protocols?.canEditProtocols?.value ||
    userRights.protocols?.canGiveAnswers?.value ||
    userRights.protocols?.canAcceptProtocols?.value ||
    userRights.rights.isAdmin;

  const canSeeProtocols =
    userRights.protocols?.canSeeProtocols?.value || userRights.rights.isAdmin;

  const canSeeRiskAssesments =
    userRights.risk_assesments?.canSeeRiskAssesments?.value ||
    userRights.rights.isAdmin;

  const canSeePlans =
    userRights.plans?.canSeePlans?.value || userRights.rights.isAdmin;

  const inCompleteProtocols = userRights.notifications?.protocols.count;
  const userIncompleteProtocolAnswers =
    userRights.notifications?.protocols.notification_answers_need_edit.length >
    0;

  const workerNotifications = userRights.notifications?.workers.count;
  const expiredPlans = userRights.notifications?.plans.count;
  const hasRemoteJobTest = userRights.rights.hasRemoteJobTest;
  const isPartner = userRights.rights.isPartner;
  const isInstructor = userRights.instructions?.isInstructor?.value;

  const Settings = canSeeWorkers && {
    title: t(tokens.nav.settings),
    path: paths.dashboard.settings,
    icon: (
      <SvgIcon fontSize="medium">
        <KeyRounded />
      </SvgIcon>
    ),
  };

  const Documents = (isInstructor ||
    canSeeDocuments ||
    canSeeWorkers ||
    canSeePlans ||
    canSeeProtocols ||
    canSeeRiskAssesments ||
    canAnswerProtocols) && {
    title: t(tokens.nav.documents),
    path: paths.dashboard.largeNavigation,
    icon: (
      <SvgIcon fontSize="small">
        <ApartmentRoundedIcon />
      </SvgIcon>
    ),

    items: [
      (canSeeWorkers || isInstructor) && [
        {
          title: t(tokens.nav.users),
          path: paths.dashboard.customers.index,
          icon: (
            <SvgIcon fontSize="small">
              <Users03Icon />
            </SvgIcon>
          ),
          label: workerNotifications > 0 && (
            <Chip color="primary" label={workerNotifications} size="small" />
          ),
        },
        canSeeWorkers && {
          title: t(tokens.nav.branches),
          path: paths.dashboard.branches,
        },
      ],
      (canSeeDocuments || isInstructor) && [
        {
          title: t(tokens.nav.occupations),
          path: paths.dashboard.occupations.index,
        },
      ],

      canSeePlans && {
        title: t(tokens.nav.plans),
        path: paths.dashboard.plans.index,
        label: expiredPlans > 0 && (
          <Chip color="primary" label={expiredPlans} size="small" />
        ),
      },
      canSeeRiskAssesments && {
        title: t(tokens.nav.risks),
        path: paths.dashboard.risks.index,
        label: userRights.notifications?.occupations.count > 0 && (
          <Chip
            color="primary"
            label={userRights.notifications?.occupations.count}
            size="small"
          />
        ),
      },

      (canSeeDocuments || isInstructor) && {
        title: t(tokens.nav.instructions),
        path: paths.dashboard.instructions.index,
      },

      canSeeDocuments && [
        {
          title: t(tokens.nav.medicalCertificates),
          path: paths.dashboard.medicalCertificates.index,
        },
        {
          title: t(tokens.nav.orders),
          path: paths.dashboard.files.index.replace(":openedFolder", "index"),
        },
        {
          title: t(tokens.nav.partnerCompanies),
          path: paths.dashboard.partnerCompanies.index,
          label: userRights.notifications?.partner_companies.count > 0 && (
            <Chip
              color="primary"
              label={userRights.notifications?.partner_companies.count}
              size="small"
            />
          ),
        },
      ],

      canSeeProtocols && {
        title: t(tokens.nav.protocols),
        path: paths.dashboard.protocols.index,
        label: inCompleteProtocols > 0 && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
      canAnswerProtocols && {
        title: t(tokens.nav.protocolAnswers),
        path: paths.dashboard.protocolAnswers.index,
        label: userIncompleteProtocolAnswers > 0 && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
    ]
      .flatMap((item) => item)
      .filter((item) => item),
  };

  console.log(userRights);

  const Academy = canSeeDocuments
    ? {
        title: t(tokens.nav.academy),
        path: paths.dashboard.academy.index,
        icon: (
          <SvgIcon fontSize="small">
            <GraduationHat01Icon />
          </SvgIcon>
        ),
      }
    : {};

  const UserDocuments = {
    title: t(tokens.nav.myDocuments),
    icon: (
      <SvgIcon fontSize="small">
        <LayoutAlt02Icon />
      </SvgIcon>
    ),
    path: paths.dashboard.academyUsers.index,
    items: [
      {
        title: t(tokens.nav.userInstructions),
        path: paths.dashboard.academyUsers.instructions.index,
        label: userRights?.notifications?.workers.notifications.some(
          (_n) =>
            _n.id === userRights?.user?.id &&
            _n.notification_invalid_instructions
        ) && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
      {
        title: t(tokens.nav.lessons),
        path: paths.dashboard.academyUsers.courses.index,
        label: userRights?.notifications?.workers.notifications.some(
          (_n) =>
            _n.id === userRights?.user?.id && _n.notification_invalid_courses
        ) && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
      {
        title: t(tokens.nav.userSignedFiles),
        path: paths.dashboard.userSignedFiles.index,
        label: userRights?.notifications?.workers.notifications.some(
          (_n) =>
            _n.id === userRights?.user?.id && _n.notification_unsigned_files
        ) && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
      isPartner && {
        title: t(tokens.nav.partnerDocuments),
        path: paths.dashboard.academyUsers.partnerDocuments.index,
      },
      hasRemoteJobTest && {
        title: t(tokens.nav.remoteJobTest),
        path: paths.dashboard.remoteJobTest.index,
        label: userRights?.notifications?.workers.notifications.some(
          (_n) => _n.id === userRights?.user?.id && _n.notification_remote_jobs
        ) && (
          <Chip
            color="primary"
            sx={{ borderRadius: "50%", height: 19, width: 19 }}
            size="small"
          />
        ),
      },
    ].filter((item) => item),
  };

  console.log([
    {
      items: [
        {
          title: t(tokens.nav.overview),
          path: paths.dashboard.index,
          icon: (
            <SvgIcon fontSize="small">
              <HomeSmileIcon />
            </SvgIcon>
          ),
        },
      ],
    },
    {
      subheader: t(tokens.nav.menuOverhead),
      items: [Settings, Academy, Documents, UserDocuments].filter(
        (item) => item
      ),
    },
  ]);

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.menuOverhead),
        items: [Settings, Academy, Documents, UserDocuments].filter(
          (el) => Object.values(el).length > 0
        ),
      },
    ];
  }, [t, userRights]);
};
