import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";

export const LoginChoice = ({ onChoosePhone, onChoosePassword }) => {
  return (
    <Stack direction="row" spacing={3}>
      <Box
        onClick={onChoosePhone}
        justifyContent={"center"}
        alignItems="center"
        sx={{
          width: 240,
          height: 71,
          backgroundColor: "primary.main",
          transform: "skewX(30deg)",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "primary.light",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            transform: "skewX(-30deg)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="button"
            color={"text.primary"}
            sx={{ p: 2, textAlign: "center" }}
          >
            Pieslēgties ar tālruņa numuru
          </Typography>
        </Box>
      </Box>
      <Box
        onClick={onChoosePassword}
        justifyContent={"center"}
        alignItems="center"
        sx={{
          width: 240,
          height: 71,
          backgroundColor: "primary.main",
          transform: "skewX(30deg)",
          transition: "0.3s",
          "&:hover": {
            backgroundColor: "primary.light",
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            transform: "skewX(-30deg)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="button"
            color={"text.primary"}
            sx={{ p: 2, textAlign: "center" }}
          >
            Pieslēgties ar paroli
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
