export const paths = {
  index: "/",
  checkout: "/checkout",
  contact: "/contact",
  pricing: "/pricing",
  sign_api: {
    index: "/sign-api",
    document_validation: "/sign-api/document-validation",
  },
  admin: {
    index: "/admin",
    largeNavigation: "/admin/large-navigation",
    companies: {
      index: "/admin/companies",
      details: "/admin/companies/:id",
      edit: "/admin/companies/edit/:id",
      add: "/admin/companies/add",
    },
    administrators: {
      index: "/admin/administrators",
      details: "/admin/administrators/:id",
      edit: "/admin/administrators/edit/:id",
      add: "/admin/add",
    },
    documentation: {
      index: "/admin/documentation",
      editor: "/admin/documentation/editor/:id",
    },
    settings: "/admin/settings",
  },
  documentation: {
    index: "/help",
    section: "/help/:id",
    companyDocumentations: {
      index: "/help/company/:company_id/:documentation_id",
      section: "/help/company/:company_id/:documentation_id/:id",
    },
  },
  chooseCompany: "/choose-company",
  dashboard: {
    index: "/dashboard",
    largeNavigation: "/dashboard/large-navigation",
    academyUsers: {
      index: "/dashboard/academyUsers",
      instructions: {
        riskAssesment: "/dashboard/academyUsers/instructions/riskAssesment/:id",
        instruction: "/dashboard/academyUsers/instructions/:id",
        index: "/dashboard/academyUsers/instructions",
      },
      tests: {
        test: "/dashboard/academyUsers/tests/:id/type/:type/:typeId",
        result: "/dashboard/academyUsers/result/:id/type/:type/:typeId",
      },
      courses: {
        course: "/dashboard/academyUsers/courses/:id",
        index: "/dashboard/academyUsers/courses",
      },
      partnerDocuments: {
        document: "/dashboard/academyUsers/partnerDocuments/:id",
        index: "/dashboard/academyUsers/partnerDocuments",
      },
    },
    academy: {
      index: "/dashboard/academy",
      add: "/dashboard/academy/courses/add",
      courseDetails: "/dashboard/academy/courses/:courseId",
      edit: "/dashboard/academy/courses/edit/:courseId",
    },
    academyInstructions: {
      index: "/dashboard/academy",
      courseDetails: "/dashboard/academy/instructions/:occupationId",
    },
    academyTests: {
      details: "/dashboard/academy/tests/:testId",
      edit: "/dashboard/academy/tests/edit/:testId",
      add: "/dashboard/academy/tests/add/:parentId",
    },
    archive: {
      index: "/dashboard/archive",
    },
    accidents: {
      index: "/dashboard/accidents",
      register: "/dashboard/accidents/register/:templateId",
      editRegistry: "/dashboard/accidents/edit-register/:id",
      addOrder: "/dashboard/accidents/add-order/:templateId",
      editOrder: "/dashboard/accidents/edit-order/:id",
      addProtocol: "/dashboard/accidents/add-protocol/:templateId",
      editProtocol: "/dashboard/accidents/edit-protocol/:id",
    },
    account: "/dashboard/account",
    blank: "/dashboard/blank",
    calendar: "/dashboard/calendar",
    customers: {
      index: "/dashboard/customers",
      details: "/dashboard/customers/:customerId",
      edit: "/dashboard/customers/:customerId/edit",
      add: "/dashboard/customers/add",
    },
    occupations: {
      index: "/dashboard/occupations",
      details: "/dashboard/occupations/:occupationId",
      edit: "/dashboard/occupations/edit/:occupationId",
      add: "/dashboard/occupations/add",
      addChild: "/dashboard/occupations/add/:occupationId",
    },
    files: {
      index: "/dashboard/files/:openedFolder",
    },
    branches: "/dashboard/branches",
    branchGraphics: "/dashboard/branchGraphics",
    instructions: {
      index: "/dashboard/instructions",
      details: "/dashboard/instructions/:instructionId",
      edit: "/dashboard/instructions/edit/:instructionId",
      add: "/dashboard/instructions/add",
    },
    risks: {
      index: "/dashboard/risks",
      details: "/dashboard/risks/:occupationId",
      edit: "/dashboard/risks/edit/:riskAssesmentId/:templateId",
    },
    medicalCertificates: {
      index: "/dashboard/medicalCertificates",
      details: "/dashboard/medicalCertificates/:occupationId",
    },
    partnerCompanies: {
      index: "/dashboard/partnerCompanies",
      list: "/dashboard/partnerCompanies/:companyId",
      details: "/dashboard/partnerCompanies/:companyId/details",
      edit: "/dashboard/partnerCompanies/:companyId/edit",
      add: "/dashboard/partnerCompanies/add",
    },
    protocols: {
      index: "/dashboard/protocols",
      list: "/dashboard/protocols/:branchId",
      details: "/dashboard/protocols/details/:protocolId",
      edit: "/dashboard/protocols/:branchId/edit/:protocolId",
      add: "/dashboard/protocols/:branchId/add/:protocolPageId",
      equipment: {
        index: "/dashboard/protocols/equipment",
        equipmentList: "/dashboard/protocols/equipment/list",
        details: "/dashboard/protocols/equipment/details/:examId",
        edit: "/dashboard/protocols/equipment/edit/:examId",
        add: "/dashboard/protocols/equipment/add/:examId",
      },
    },
    protocolAnswers: {
      index: "/dashboard/protocolAnswers",
      details: "/dashboard/protocolAnswers/:protocolId",
      edit: "/dashboard/protocolAnswers/edit/:protocolId",
    },
    plans: {
      index: "/dashboard/plans",
      details: "/dashboard/plans/:planId",
      edit: "/dashboard/plans/edit/:planId",
      add: "/dashboard/plans/add/:planId",
    },
    remoteJobTest: {
      index: "/dashboard/remoteJobTest",
    },
    userSignedFiles: {
      index: "/dashboard/userSignedFiles",
    },
    settings: "/dashboard/settings",
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
