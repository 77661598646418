import { remoteJobTestQuestionsEng } from "src/jsons/remote_job_questions_eng";
import { tokens } from "../tokens";

export const en = {
  [tokens.common.languageChanged]: "Language Changed",
  [tokens.nav.documents]: "My Company",
  [tokens.nav.myDocuments]: "My Documents",
  [tokens.nav.occupations]: "Occupations",
  [tokens.nav.branches]: "Branches",
  [tokens.nav.instructions]: "Instructions",
  [tokens.nav.userInstructions]: "Instructions",
  [tokens.nav.orders]: "Orders",
  [tokens.nav.risks]: "Risks",
  [tokens.nav.signatureJournals]: "Signature journals",
  [tokens.nav.medicalCertificates]: "Medical Certificates",
  [tokens.nav.protocols]: "Inspection protocols",
  [tokens.nav.plans]: "Health and safety plans",
  [tokens.nav.academy]: "Cerera Academy",
  [tokens.nav.documentation]: "Cerera tutorial",
  [tokens.nav.lessons]: "Lessons",
  [tokens.nav.account]: "Account",
  [tokens.nav.calendar]: "Calendar",
  [tokens.nav.menuOverhead]: "Cerera",
  [tokens.nav.course]: "Course",
  [tokens.nav.users]: "Workers",
  [tokens.nav.fileManager]: "File Manager",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.partnerCompanies]: "Partnering companies",
  [tokens.nav.partnerDocuments]: "Iepazīstināšanas dokumenti",
  [tokens.nav.remoteJobTest]: "Remote job test",
  [tokens.nav.userSignedFiles]: "Documents to sign",
  [tokens.nav.settings]: "Settings",

  [tokens.adminNav.documentation]: "Users guide",
  [tokens.adminNav.administration]: "Administration",
  [tokens.adminNav.companies]: "Companies",
  [tokens.adminNav.administrators]: "Administrators",
  [tokens.adminNav.settings]: "Settings",

  [tokens.remoteJob.test]: remoteJobTestQuestionsEng,

  [tokens.remoteJob.start.title]: "Remote workplace self-examination",

  [tokens.remoteJob.edit.title]: "Remote workplace self-examination",
  [tokens.remoteJob.edit.addPictures]: "Add workplace images",
  [tokens.remoteJob.edit.pictures.primary]: "View from the back",
  [tokens.remoteJob.edit.pictures.side]: "View from the side",

  [tokens.remoteJob.result.title]: "Remote workplace self-examination",
  [tokens.remoteJob.result.answers]: "Answers marked with yes",
  [tokens.remoteJob.result.missing]: "Answers marked with no",
  [tokens.remoteJob.result.addedPictures]: "Added images",

  [tokens.hints.yes]: "Yes",
  [tokens.hints.no]: "No",
  [tokens.hints.question]: "Question",
  [tokens.hints.more]: "More",

  [tokens.buttons.save]: "Save",
  [tokens.buttons.edit]: "Edit",
  [tokens.buttons.cancel]: "Cancel",
  [tokens.buttons.ready]: "Ready",
  [tokens.buttons.start]: "Start",
  [tokens.buttons.delete]: "Delete",
  [tokens.buttons.download]: "Download",
  [tokens.buttons.previous]: "Previous",
  [tokens.buttons.next]: "Next",
  [tokens.buttons.finish]: "Submit",

  [tokens.generic.greeting]: "Hi",
  [tokens.generic.learningGreeting]: "Time for learning",
  [tokens.generic.learningSuccess]: "Great, all tasks complete!",
  [tokens.generic.expressionGreat]: "Great",
  [tokens.generic.learningNoTasks]: "You have no assigned tasks",

  [tokens.instructions.signJournal]: "Sign journal",
  [tokens.instructions.eJournalAvailable]: "You have e-Journals available",
  [tokens.instructions.signableDocumentHint]:
    "Your signature journal is being prepared, you will be notified once it's ready",

  [tokens.academy.startTest]: "Begin test",
  [tokens.academy.endTest]: "Submit answers",
  [tokens.academy.testDone]: "Complete",
  [tokens.academy.markTestAsRead]: "Mark as read",
  [tokens.academy.readDocument]: "Read documentation",
  [tokens.academy.riskAssesmentRead]: "Risk assessment is read",
  [tokens.academy.instructionName]: "Instruction name",
  [tokens.academy.courseName]: "Course name",
  [tokens.academy.progress]: "Progress",
  [tokens.academy.actions]: "Actions",
  [tokens.academy.progressIncomplete]: "Incomplete",
  [tokens.academy.readMaterial]: "Read material",
  [tokens.academy.riskAssesment]: "Risk assessment",
  [tokens.academy.lesson]: "Lesson",
  [tokens.academy.document]: "Documents",
  [tokens.academy.description]: "Description",
  [tokens.academy.question]: "Question",
  [tokens.academy.questions]: "Questions",
  [tokens.academy.from]: "of",
  [tokens.academy.imageZoomHint]: "Click the image to expand it",
  [tokens.academy.answerSelectHint]: "Choose one of the answers below",
  [tokens.academy.zoomIconHint]: "Expand",
  [tokens.academy.answered]: "Answered",
  [tokens.academy.notAnswered]: "Not answered",

  [tokens.results.incorrectAnswers]: "Incorrect answers",
  [tokens.results.titleSuccess]: "Test complete",
  [tokens.results.titleFail]: "Test did not pass",
  [tokens.results.goToMainPage]: "Go to main page",
  [tokens.results.goToNextTest]: "Go to the next lesson",
  [tokens.results.retakeTest]: "Retake test",
  [tokens.results.result]: "Result",
  [tokens.results.answer]: "Answer",

  [tokens.partnerDocuments.noDocumentsToRead]:
    "Currently there are no documents to be read or uploaded",
  [tokens.partnerDocuments.readingExplanation]:
    "The documents that are necessary to read",
  [tokens.partnerDocuments.uploadingExplanation]:
    "The documents that are necessary to upload",
  [tokens.partnerDocuments.startReading]: "Start",
  [tokens.partnerDocuments.documentRead]: "Document is read",
  [tokens.partnerDocuments.documentName]: "Document name",
  [tokens.partnerDocuments.documentReadStatus]: "Read",
  [tokens.partnerDocuments.documentNotReadStatus]: "Not read",
  [tokens.partnerDocuments.filesAdded]: "Files are uploaded",
  [tokens.partnerDocuments.filesNotAdded]: "Files are not uploaded",
  [tokens.partnerDocuments.description]: "Description",
  [tokens.partnerDocuments.addedDocuments]: "Uploaded documents",
  [tokens.partnerDocuments.addFile]: "Upload file",

  [tokens.documentsToSign.noDocumentsToSign]:
    "Currently there are no documents to sign",
  [tokens.documentsToSign.explanationOne]:
    "Here are the documents you need to sign",
  [tokens.documentsToSign.explanationTwo]:
    "To do this, first download the document from the list, electronically sign it, and then press the 'Sign' button to upload the electronically signed document.",
  [tokens.documentsToSign.workSafetyJournal]: "Work safety journal",
  [tokens.documentsToSign.fireSafetyJournal]: "Fire safety journal",
  [tokens.documentsToSign.introductoryJournal]: "Introductory training journal",
  [tokens.documentsToSign.uploadSignedFile]: "Upload signed file",
  [tokens.documentsToSign.signed]: "Signed",
  [tokens.documentsToSign.unsigned]: "Not signed",
};
