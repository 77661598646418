import {  amber } from './colors';

export const getPrimary = (preset) => {
  switch (preset) {
    case 'amber':
      return amber;
    default:
      console.error('Invalid color preset, accepted values: "blue", "green", "indigo" or "purple"".');
      return amber;
  }
};
