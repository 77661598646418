import ReactDOM from "react-dom/client";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { App } from "./App";
import { IS_DEBUG } from "./api/api-routes/routes";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (!IS_DEBUG) {
  console.log = () => {};
}

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
