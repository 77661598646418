import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { VerticalLayout } from "./vertical-layout";
import { SplashScreen } from "src/components/splash-screen";
import DocumentationContext from "./documentation-context";
import axios from "axios";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";
import { paths } from "src/paths";
import { useParams } from "react-router";
import { useAlerts } from "src/hooks/use-alerts";
import { validateJWTToken } from "src/api/auth/login";

export const Layout = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { createAlert } = useAlerts();
  const { company_id, documentation_id } = useParams();

  const settings = useSettings();
  const sections = useSections({ sections: data });

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setLoading(true);
      validateJWTToken()
        .then(() => {
          if (window.location.pathname.includes("company")) {
            fetchCompanyDocumentationHeaders();
          } else {
            fetchDocumentationHeaders();
          }
          setLoading(false);
        })
        .catch((err) => (window.location.href = paths[401]));
    } else {
      window.location.href = paths[404];
    }
  }, []);

  const fetchDocumentationHeaders = async () => {
    await axios
      .get(apiRoutes.getDocumentationSections, apiHeaders())
      .then((res) =>
        setData(
          res.data.items.map((_section) => ({
            ..._section,
            path: paths.documentation.section.replace(":id", _section.id),
          }))
        )
      );
  };

  const fetchCompanyDocumentationHeaders = async () => {
    const companySections = await axios
      .get(
        apiRoutes.getCompanyDocumentation,
        apiHeaders({}, { documentation_id: documentation_id })
      )
      .then((res) => res.data)
      .catch((err) =>
        createAlert("error", "Neizdevās iegūt uzņēmuma apmācību")
      );

    const fetchedSections = await axios
      .get(apiRoutes.getDocumentationSections, apiHeaders())
      .then((res) => res.data)
      .catch((err) => createAlert("error", "Neizdevās iegūt apmācības"));

    setData((prevState) =>
      fetchedSections.items
        .map((_section) => {
          const foundSection = companySections.sections.find(
            (_s) => _s.id === _section.id
          );

          if (foundSection) {
            return {
              ..._section,
              path: paths.documentation.companyDocumentations.section
                .replace(":company_id", company_id)
                .replace(":documentation_id", documentation_id)
                .replace(":id", _section.id),
            };
          }
        })
        .filter((_el) => _el)
    );
  };

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <DocumentationContext.Provider
          value={{
            data: data,
            setData: setData,
          }}
        >
          <VerticalLayout
            sections={sections}
            navColor={settings.navColor}
            {...props}
          />
        </DocumentationContext.Provider>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
