import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";

import { paths } from "src/paths";

const Page = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Seo title="Error: Not Found" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src="/assets/errors/error-404.png"
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? "h1" : "h4"}>
            404: Lapa kuru meklējat nav šeit
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            Iespējams jūs ievadījāt nosaukumu nepareizi, vai nonācāt šeit
            netīšām. Jebkurā gadījumā, droši dodaties atpakaļ uz galveno lapu!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button component={RouterLink} href={paths.index}>
              Uz galveno lapu
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
