import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import Mail01Icon from "@untitled-ui/icons-react/build/esm/Mail01";
import {
  Avatar,
  Box,
  Container,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { LogoAccenture } from "src/components/logos/logo-accenture";
import { LogoAtt } from "src/components/logos/logo-att";
import { LogoAws } from "src/components/logos/logo-aws";
import { LogoBolt } from "src/components/logos/logo-bolt";
import { LogoSamsung } from "src/components/logos/logo-samsung";
import { LogoVisma } from "src/components/logos/logo-visma";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";

import { paths } from "src/paths";
import { ContactForm } from "src/sections/contact/contact-form";

const Page = () => {
  return (
    <>
      <Seo title="Sazināties ar mums" />
      <Box
        component="main"
        sx={{
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "neutral.800" : "neutral.50",
            py: 8,
          }}
        >
          <Container maxWidth="md" sx={{ pl: { lg: 15 } }}>
            <Stack spacing={3}>
              <div>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.index}
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">Atpakaļ</Typography>
                </Link>
              </div>
            </Stack>

            <Typography sx={{ my: 6 }} variant="h1" align="left">
              Sazināties ar mūsu speciālistiem
            </Typography>
            <Typography sx={{ mb: 3 }} variant="body1">
              Vēlaties sākt lietot vai uzzināt vairāk par Cerera darba drošības
              platformu? Aizpildiet anketu un mūsu speciālisti tuvākajā laikā
              sazināsies ar Jums!
            </Typography>
            <Typography color="primary" sx={{ mb: 3 }} variant="h6">
              Kļūstiet par vienu no uzņēmumiem, kas jau izmanto mūsu
              pakalpojumus
            </Typography>
            <Stack
              alignItems="center"
              direction="row"
              flexWrap="wrap"
              gap={4}
              sx={{
                color: "text.primary",
                "& > *": {
                  flex: "0 0 auto",
                },
              }}
            >
              <img
                src="https://osh.lv/assets/images/logo-dark.png"
                style={{
                  maxWidth: 150,
                  mixBlendMode: "multiply",
                  filter: "grayscale(100%)",
                }}
              />
            </Stack>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "background.paper",
            px: 6,
            py: 15,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pr: {
                lg: 15,
              },
            }}
          >
            <ContactForm />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Page;
