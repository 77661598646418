export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },

  generic: {
    greeting: "generic.greeting",
    learningGreeting: "generic.learningGreeting",
    learningSuccess: "generic.learningSuccess",
    learningNoTasks: "generic.learningNoTasks",
    expressionGreat: "generic.expressionGreat",
  },

  nav: {
    documents: "nav.documents",
    myDocuments: "nav.myDocuments",
    occupations: "nav.occupations",
    branches: "nav.branches",
    instructions: "nav.instructions",
    userInstructions: "nav.userInstructions",
    orders: "nav.orders",
    risks: "nav.risks",
    medicalCertificates: "nav.medicalCertificates",
    protocolHome: "nav.protocolHome",
    protocols: "nav.protocols",
    protocolPages: "nav.protocolPages",
    protocolAnswers: "nav.protocolAnswers",
    signatureJournals: "nav.signatureJournals",
    plans: "nav.plans",
    academy: "nav.academy",
    lessons: "nav.lessons",
    documentation: "nav.documentation",
    remoteJobTest: "nav.remoteJobTest",
    partnerDocuments: "nav.partnerDocuments",
    account: "nav.account",
    calendar: "nav.calendar",
    menuOverhead: "nav.concepts",
    course: "nav.course",
    users: "nav.customers",
    administration: "nav.administration",
    fileManager: "nav.fileManager",
    overview: "nav.overview",
    profile: "nav.profile",
    partnerCompanies: "nav.partnerCompanies",
    userSignedFiles: "nav.userSignedFiles",
    settings: "nav.settings",
  },

  adminNav: {
    documentation: "adminNav.documentation",
    administration: "adminNav.administration",
    companies: "adminNav.companies",
    administrators: "adminNav.administrators",
    settings: "adminNav.settings",
  },

  remoteJob: {
    test: "remoteJob.test",
    start: {
      title: "remoteJob.start.title",
    },
    edit: {
      title: "remoteJob.edit.title",
      addPictures: "remoteJob.edit.addPictures",
      pictures: {
        primary: "remoteJob.edit.pictures.primary",
        side: "remoteJob.edit.pictures.side",
      },
    },
    result: {
      title: "remoteJob.result.title",
      answers: "remoteJob.result.answers",
      missing: "remoteJob.result.missing",
      addedPictures: "remoteJob.result.addedPictures",
    },
  },

  instructions: {
    signJournal: "instructions.signJournal",
    eJournalAvailable: "instructions.eJournalAvailable",
    signableDocumentHint: "instructions.signableDocumentHint",
  },

  results: {
    incorrectAnswers: "results.incorrectAnswers",
    result: "results.result",
    titleSuccess: "results.titleSuccess",
    titleFail: "results.titleFail",
    retakeTest: "results.retakeTests",
    goToNextTest: "results.goToNextTest",
    goToMainPage: "results.goToNextPage",
    answer: "results.answer",
  },

  partnerDocuments: {
    startReading: "partnerDocuments.startReading",
    readingExplanation: "partnerDocuments.readingExplanation",
    uploadingExplanation: "partnerDocuments.uploadingExplanation",
    noDocumentsToRead: "partnerDocuments.noDocumentsToRead",
    documentRead: "partnerDocuments.documentRead",
    documentName: "partnerDocuments.documentName",
    documentReadStatus: "partnerDocuments.documentReadStatus",
    documentNotReadStatus: "partnerDocuments.documentNotReadStatus",
    filesAdded: "partnerDocuments.filesAdded",
    filesNotAdded: "partnerDocuments.filesNotAdded",
    description: "partnerDocuments.description",
    addedDocuments: "partnerDocuments.addedDocuments",
    addFile: "partnerDocuments.addFile",
  },

  documentsToSign: {
    noDocumentsToSign: "documentsToSign.noDocumentsToSign",
    explanationOne: "documentsToSign.explanationOne",
    explanationTwo: "documentsToSign.explanationTwo",
    workSafetyJournal: "documentsToSign.workSafetyJournal",
    fireSafetyJournal: "documentsToSign.fireSafetyJournal",
    introductoryJournal: "documentsToSign.introductoryJournal",
    uploadSignedFile: "documentsToSign.uploadSignedFile",
    signed: "documentsToSign.signed",
    unsigned: "documentsToSign.unsigned",
  },

  academy: {
    zoomIconHint: "academy.zoomIconHint",
    question: "academy.question",
    questions: "academy.questions",
    from: "academy.from",
    imageZoomHint: "academy.imageZoomHint",
    answerSelectHint: "academy.answerSelectHint",
    lesson: "academy.lesson",
    document: "academy.document",
    description: "academy.description",
    startTest: "academy.startTest",
    endTest: "academy.endTest",
    testDone: "academy.testDone",
    markTestAsRead: "academy.markTestAsRead",
    readDocument: "academy.readDocument",
    instructionName: "academy.instructionName",
    courseName: "academy.courseName",
    progress: "academy.progress",
    actions: "academy.actions",
    progressIncomplete: "academy.progressIncomplete",
    readMaterial: "academy.readMaterial",
    riskAssesment: "academy.riskAssesment",
    riskAssesmentRead: "academy.riskAssesmentRead",
    answered: "academy.answered",
    notAnswered: "academy.notAnswered",
  },

  hints: {
    question: "hints.question",
    yes: "hints.yes",
    no: "hints.no",
    more: "hints.more",
  },

  buttons: {
    save: "buttons.save",
    cancel: "buttons.cancel",
    edit: "buttons.edit",
    ready: "buttons.ready",
    start: "buttons.start",
    delete: "buttons.delete",
    download: "buttons.download",
    next: "buttons.next",
    previous: "buttons.previous",
    finish: "buttons.finish",
  },

  documentation: {
    title: "",
  },
};
