import React, { useState, useEffect } from "react";
import { Box, Stack, TextField } from "@mui/material";

export const CodeVerificationField = ({
  textFieldRef,
  setFocusIndex,
  handleInput,
  onKeyDown,
}) => {
  const [animateMargin, setAnimateMargin] = useState(true);
  const [marginRight, setMarginRight] = useState(0);

  useEffect(() => {
    if (animateMargin) {
      const animationTimeout = setTimeout(() => {
        setMarginRight(2);
        setAnimateMargin(false);
      }, 0);

      return () => {
        clearTimeout(animationTimeout);
      };
    } else {
      setMarginRight(textFieldRef.value.length === 0 ? 2 : 0);
    }
  }, [animateMargin, textFieldRef.value.length]);

  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        transform: "skew(30deg)",
        margin: "auto",
        marginRight: `${marginRight}rem`,
        transition: "0.3s",
      }}
    >
      <Stack
        sx={{
          transform: "skew(-30deg)",
          width: 60,
          height: 71,
          margin: "auto",
        }}
        alignItems={"center"}
        justifyContent="center"
      >
        <Stack alignItems={"center"} justifyContent="center">
          <TextField
            onKeyDown={(event) => onKeyDown(event, textFieldRef.index)}
            inputRef={textFieldRef.ref}
            onFocus={() => setFocusIndex(textFieldRef.index)}
            onChange={(e) => handleInput(e, textFieldRef.index)}
            value={textFieldRef.value}
            sx={{ marginBottom: 2 }}
            variant="standard"
            InputProps={{
              style: {
                width: 40,
                autoComplete: "off",
              },
            }}
            inputProps={{
              style: {
                textAlign: "center",
                fontSize: "20px",
                borderWidth: 2,
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
