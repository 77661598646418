import { ApartmentOutlined, ShieldOutlined } from "@mui/icons-material";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { paths } from "src/paths";
import { RouterLink } from "../router-link";

export const CompanyPopover = (props) => {
  const {
    isAdmin,
    anchorEl,
    onChange,
    onClose,
    open = false,
    companies,
    ...other
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 190, mt: 1 } }}
      {...other}
    >
      <Stack justifyContent={"center"}>
        {companies.map((company, index) => (
          <Button
            key={company.company_id}
            sx={{
              borderRadius: 0,
              borderTop: index > 0 ? "2px solid lightgray" : "none",
            }}
            fullWidth
            size="small"
            variant="text"
            color="inherit"
            onClick={() => onChange?.(company.company_id)}
          >
            <Typography
              textAlign={"left"}
              sx={{ width: "100%" }}
              variant={"subtitle2"}
            >
              {company.company_name}
            </Typography>
          </Button>
        ))}
        {isAdmin && (
          <Button
            sx={{
              borderRadius: 0,
              justifyContent: "space-between",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
            }}
            variant="outlined"
            color="inherit"
            endIcon={<ShieldOutlined />}
            href={paths.admin.index}
            LinkComponent={RouterLink}
          >
            Administrācija
          </Button>
        )}
        <Button
          sx={{ borderRadius: 0, justifyContent: "space-between" }}
          variant="contained"
          href={paths.chooseCompany}
          LinkComponent={RouterLink}
          endIcon={<ApartmentOutlined />}
        >
          Mainīt uzņēmumu
        </Button>
      </Stack>
    </Popover>
  );
};
