import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const ToolTipButton = (props) => {
  const { title, icon, onClick, disabled } = props;

  return (
    <Tooltip title={title}>
      <IconButton disabled={disabled} onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

ToolTipButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ToolTipButton;
