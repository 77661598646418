import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DocumentationLayout } from "src/layouts/documentation";

const IndexPage = lazy(() => import("src/pages/documentation/index"));
const SectionPage = lazy(() =>
  import("src/pages/documentation/section/section")
);

export const documentationRoutes = [
  {
    path: "help",
    element: (
      <DocumentationLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DocumentationLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: ":id",
        element: <SectionPage />,
      },
      {
        path: "company/:company_id/:documentation_id",
        element: <IndexPage />,
      },
      {
        path: "company/:company_id/:documentation_id/:id",
        element: <SectionPage />,
      },
    ],
  },
];
