import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

export const SkewedButton = ({ onClick, name }) => {
  return (
    <Stack
      onClick={onClick}
      justifyContent={"center"}
      alignItems="center"
      sx={{
        width: "100%",
        height: 71,
        backgroundColor: "primary.main",
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "primary.light",
        },
      }}
    >
      <Typography
        variant="h6"
        color={"text.primary"}
        onClick={onClick}
        sx={{
          transform: "skewX(-30deg)",
          margin: "0 0",
          "&:hover": { cursor: "pointer", textDecoration: "underline" },
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};
