import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as AdminLayout } from "src/layouts/admin";

const IndexPage = lazy(() => import("src/pages/admin/index"));

// Large Navigation
const LargeNavigation = lazy(() => import("src/pages/admin/largeNavigation"));

// Companies
const CompaniesListPage = lazy(() => import("src/pages/admin/companies/list"));
const CompaniesDetailPage = lazy(() =>
  import("src/pages/admin/companies/detail")
);
const CompaniesEditPage = lazy(() => import("src/pages/admin/companies/edit"));
const CompaniesAddPage = lazy(() => import("src/pages/admin/companies/add"));

// Companies
const AdministratorsListPage = lazy(() =>
  import("src/pages/admin/administrators/list")
);
const AdministratorsDetailPage = lazy(() =>
  import("src/pages/admin/administrators/detail")
);

// Documentation
const DocumentationList = lazy(() =>
  import("src/pages/admin/documentation/index")
);
const DocumentationEditor = lazy(() =>
  import("src/pages/admin/documentation/editor")
);

// Settings
const Settings = lazy(() => import("src/pages/admin/settings/index"));

export const adminRoutes = [
  {
    path: "admin",
    element: (
      <AdminLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </AdminLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "large-navigation",
        element: <LargeNavigation />,
      },
      {
        path: "companies",
        children: [
          { index: true, element: <CompaniesListPage /> },
          {
            path: "add",
            element: <CompaniesAddPage />,
          },
          {
            path: ":id",
            element: <CompaniesDetailPage />,
          },
          {
            path: "edit/:id",
            element: <CompaniesEditPage />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "administrators",
        children: [
          { index: true, element: <AdministratorsListPage /> },
          {
            path: ":id",
            element: <AdministratorsDetailPage />,
          },
        ],
      },
      {
        path: "documentation",
        children: [
          { index: true, element: <DocumentationList /> },
          {
            path: "editor/:id",
            element: <DocumentationEditor />,
          },
        ],
      },
    ],
  },
];
