import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useSettings } from "src/hooks/use-settings";
import { VerticalLayout } from "./vertical-layout";
import { SplashScreen } from "src/components/splash-screen";
import { paths } from "src/paths";
import { getBaseUserData, validateJWTToken } from "src/api/auth/login";
import userData from "src/contexts/user-data-context";

export const Layout = (props) => {
  const settings = useSettings();

  const { data, setData } = useContext(userData);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      validateJWTToken()
        .then(() =>
          getBaseUserData(sessionStorage.getItem("user_id")).then((res) => {
            console.log(res);
            setData((prevState) => ({ user: res }));
          })
        )
        .catch((err) => (window.location.href = paths[401]));
    } else {
      window.location.href = paths[404];
    }
  }, []);

  return (
    <>
      {!data ? (
        <SplashScreen />
      ) : (
        <VerticalLayout navColor={settings.navColor} {...props} />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
