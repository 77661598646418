import { Box, Container, Stack, Typography } from "@mui/material";
import { Logo } from "src/components/logo";
import { HeroText } from "./home-hero-text";
import LoginPage from "src/pages/auth/desktopVersion/login";

export const HomeHeroDesktop = () => {
  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        pt: "120px",
      }}
    >
      <Container maxWidth="100%">
        <Stack direction={{ lg: "column" }} justifyContent={"space-between"}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Stack
              alignItems={"center"}
              sx={{ position: "relative", bottom: 150, width: "100%" }}
            >
              <Box sx={{ position: "relative", top: 225, left: 150 }}>
                <HeroText />
              </Box>

              <Stack
                direction={"row"}
                spacing={3}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Logo height={400} width={400} />
                <Stack
                  direction={"row"}
                  spacing={3}
                  sx={{ position: "relative", right: 165 }}
                >
                  <LoginPage />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack direction={"row"} justifyContent="center" spacing={5}>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ fontWeight: 500 }}
          >
            All rights reserved.
          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{ fontWeight: 500 }}
          >
            ©{new Date().getFullYear()} Cerera
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};
