import { Box, Stack, Typography } from "@mui/material";
import { LogoBulletPoint } from "src/components/logo-bullet-point";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const BulletPoint = ({ name, index }) => {
  return (
    <Stack direction={"row"} justifyContent={"start"} spacing={1} width={200}>
      {/* <LogoBulletPoint width={"30"} height={"41"} /> */}
      <Box
        sx={{
          width: 15,
          height: 15,
          borderRadius: 0.3,
          margin: "auto",
          borderTop: "5px solid",
          borderRight: "5px solid",
          borderColor: "primary.main",
          transform: "rotate(45deg) ",
        }}
      />

      <Box width={"90%"}>
        <Typography
          color="text.secondary"
          fontWeight={"900"}
          fontSize="1.7rem"
          sx={{ "font-family": "'Plus Jakarta Sans',sans-serif" }}
        >
          {name}
        </Typography>
      </Box>
    </Stack>
  );
};
