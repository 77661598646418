import PropTypes from "prop-types";
import { VerticalLayout } from "./vertical-layout";

export const Layout = (props) => {
  return <VerticalLayout {...props} />;
};

Layout.propTypes = {
  children: PropTypes.node,
};
