import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { CountryCode } from "./country_code";
import PropTypes from "prop-types";

const PhoneInput = ({
  countryCode,
  setCountryCode,
  setPhone,
  phone,
  countryCodeInputName,
  ...props
}) => {
  const { name, disabled } = props;
  const [value, setValue] = useState(phone);

  const validatePhone = (input) => {
    if (input.length > 15) {
      return input.splice(0, input.length - 1);
    }

    const ALLOWED_CHARS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    // check if last input character is allowed
    input = ALLOWED_CHARS.includes(input[input.length - 1])
      ? input
      : input.slice(0, input.length - 1);

    // there is a bug that allows space being entered into the input field without triggering the onChange
    input = [...input].filter((char) => char != " ").join("");

    return input;
  };

  useEffect(() => {
    setValue(phone);
  }, [phone]);

  return (
    <TextField
      disabled={disabled}
      variant="outlined"
      label="Tālrunis"
      id="phone_number"
      name={name ?? "phone_number"}
      onChange={(e) => {
        setValue(validatePhone(e.target.value));
        if (setPhone) {
          setPhone(validatePhone(e.target.value));
        }
      }}
      type="phone"
      value={value}
      InputProps={{
        startAdornment: (
          <CountryCode
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            countryCodeInputName={countryCodeInputName}
            disabled={disabled}
          />
        ),
      }}
      {...props}
    />
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string,
  countryCodeInputName: PropTypes.string,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PhoneInput;
