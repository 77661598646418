import { useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import userData from "src/contexts/user-data-context";

import { HomeHeroPhone } from "./home-hero-phone";
import { HomeHeroDesktop } from "./home-hero-desktop";
import { paths } from "src/paths";
import { validateJWTToken } from "src/api/auth/login";
import { SplashScreen } from "src/components/splash-screen";
import { useAlerts } from "src/hooks/use-alerts";

export const HomeHero = () => {
  const { data } = useContext(userData);
  const { createAlert } = useAlerts();

  const [loading, setLoading] = useState();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setLoading(true);
      validateJWTToken()
        .then(() => (window.location.href = paths.chooseCompany))
        .catch((err) => createAlert("error", "Notikusi kļūda pierakstoties."));
    }
  }, [data]);

  if (loading) {
    return <SplashScreen />;
  }

  return isSmallScreen ? <HomeHeroPhone /> : <HomeHeroDesktop />;
};
