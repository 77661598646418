import PropTypes from "prop-types";
import { Box, List, Stack, Typography } from "@mui/material";
import { Scrollbar } from "src/components/scrollbar";
import { getInitials } from "src/utils/get-initials";
import { paths } from "src/paths";
import { Notification } from "src/components/notification";
import { NotificationHint } from "./notification-hint";

export const NotificationsList = ({ notifications }) => {
  const isEmpty = notifications?.count === 0;

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          Paziņojumi
        </Typography>
      </Stack>
      {isEmpty ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">Paziņojumu nav</Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400, maxWidth: "100%" }}>
          <List disablePadding>
            <NotificationHint
              count={notifications?.workers.count ?? ""}
              title={
                notifications?.workers.count === 1
                  ? "Darbinieku paziņojums"
                  : "Darbinieku paziņojumi"
              }
            />
            {notifications?.workers.notifications.map((notification, index) => (
              <Notification
                key={index}
                avatar={getInitials(
                  `${notification.name} ${notification.surname}`
                )}
                header={`${notification.name} ${notification.surname}`}
                action={[
                  notification.notification_expired_certificates &&
                    "Obligāto veselības pārbaudi",
                  notification.notification_invalid_instructions &&
                    "Instruktāžu",
                  notification.notification_instructions_to_sign &&
                    "Reģistrēt parakstu par instruktāžas izpildi",
                  notification.notification_invalid_courses &&
                    "Iekšējo apmācību",
                  notification.notification_invalid_outside_courses &&
                    "Ārējo apmācību",
                  notification.notification_remote_jobs && "Pašnovērtējumu",
                  notification.notification_expired_equipment &&
                    "IAL pārskatīšanu",
                  (notification.notification_admin_unsigned_files ||
                    notification.notification_unsigned_files) &&
                    "Dokumenta parakstīšanu",
                ]
                  .filter((el) => el)
                  .join(", ")}
                url={paths.dashboard.customers.details.replace(
                  ":customerId",
                  notification.id
                )}
              />
            ))}

            <NotificationHint
              count={notifications?.occupations.count ?? ""}
              title={
                notifications?.occupations.count === 1
                  ? "Amatu paziņojums"
                  : "Amatu paziņojumi"
              }
            />
            {notifications?.occupations.notifications.map(
              (notification, index) => (
                <Notification
                  key={index}
                  avatar={getInitials(notification.occupation_name)}
                  header={notification.occupation_name}
                  action={"Riska novērtējumu"}
                  url={paths.dashboard.risks.index}
                />
              )
            )}

            <NotificationHint
              count={notifications?.protocols.count}
              title={"Uzraudzības aktu paziņojums"}
            />
            {notifications?.protocols.count > 0 && (
              <Notification
                avatar={"U"}
                header={"Uzraudzības akts"}
                action={[
                  notifications?.protocols.notification_drafts.length > 0 &&
                    "Melnraksta pabeigšana",
                  notifications?.protocols
                    .notification_descriptions_without_answers.length > 0 &&
                    "Jāiesniedz atbilde",
                  notifications?.protocols
                    .notification_answers_need_confirmation.length > 0 &&
                    "Jāveic atbildes apstiprināšana",
                  notifications?.protocols.notification_answers_need_edit
                    .length > 0 && "Jāievieš izmaiņas atbildē",
                ]
                  .filter((el) => el)
                  .join(", ")}
                url={paths.dashboard.protocols.index}
              />
            )}

            <NotificationHint
              count={notifications?.plans.count}
              title={"DA plānu paziņojums"}
            />
            {notifications?.plans.count > 0 && (
              <Notification
                avatar={"P"}
                header={"Plāna pasākums"}
                action={"Izmaiņas"}
                url={paths.dashboard.plans.index}
              />
            )}
            <NotificationHint
              count={notifications?.partner_companies.count}
              title={"Sadarbības uzņēmumi"}
            />
            {notifications?.partner_companies.count > 0 &&
              notifications?.partner_companies.notifications?.map((_c) => (
                <Notification
                  avatar={getInitials(_c.name)}
                  header={_c.name}
                  action={"dokumenta apstiprināšana"}
                  url={paths.dashboard.partnerCompanies.index}
                />
              ))}
          </List>
        </Scrollbar>
      )}
    </>
  );
};
