import { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Settings04Icon from "@untitled-ui/icons-react/build/esm/Settings04";
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import userData from "src/contexts/user-data-context";

import axios from "axios";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, canEdit, ...other } = props;
  const router = useRouter();
  const { data } = useContext(userData);

  const handleLogout = useCallback(async () => {
    axios.post(apiRoutes.logout, JSON.stringify({}), apiHeaders()).then(() => {
      sessionStorage.clear();
      window.location.href = paths.index;
    });
  }, [router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{data.user.name}</Typography>
        <Typography color="text.secondary" variant="body2">
          {data.user.mail}
        </Typography>
      </Box>
      <Divider />
      {canEdit ? (
        <>
          <Box sx={{ p: 1 }}>
            <ListItemButton
              component={RouterLink}
              href={paths.dashboard.account}
              onClick={onClose}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="small">
                  <Settings04Icon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">Iestatījumi</Typography>}
              />
            </ListItemButton>
          </Box>
          <Divider sx={{ my: "0 !important" }} />
        </>
      ) : (
        ""
      )}

      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Izrakstīties
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
