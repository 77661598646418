import { Stack } from "@mui/material";
import { AlertComponent } from "src/components/alert-component";
import { useAlerts } from "src/hooks/use-alerts";

export const Alerts = () => {
  const { alerts, removeAlert } = useAlerts();

  return (
    <Stack
      sx={{
        position: "fixed",
        width: "70vw",
        left: 0,
        right: 0,
        top: 0,
        margin: "auto",
        zIndex: 9999,
      }}
      spacing={0.5}
    >
      {alerts.map((alert) => (
        <AlertComponent
          key={alert.id}
          onClose={() => {
            removeAlert(alert.id);
            alert.onClose(alert);
          }}
          message={alert.message}
          variant={alert.variant}
          severity={alert.severity}
          duration={alert.duration}
        />
      ))}
    </Stack>
  );
};
