import {
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { countriesCallingCodes } from "src/jsons/countries_calling_codes";

export const CountryCode = ({
  countryCode,
  setCountryCode,
  disabled,
  countryCodeInputName,
}) => {
  const [chosenCountry, setChosenCountry] = useState("LV");
  const [value, setValue] = useState(countryCode);

  const getCountryCode = () => {
    if (!value) {
      if (setCountryCode) {
        setCountryCode("371");
      }

      setValue("371");
      setChosenCountry("LV");
    }

    const foundCode = countriesCallingCodes.find(
      (country) => country.dial_code === value
    );

    setChosenCountry(foundCode?.code ?? "LV");
  };

  useEffect(() => {
    getCountryCode();
  }, [value]);

  return (
    <InputAdornment position="start">
      <Input
        sx={{ display: "none" }}
        disabled
        value={value}
        name={countryCodeInputName ?? "country"}
      />
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={"center"}
        alignItems={"center"}
        mb={0.4}
      >
        <Select
          variant="standard"
          disableUnderline
          disabled={disabled}
          renderValue={() => (
            <span
              style={{ width: 35, height: 25, borderRadius: 5 }}
              className={`fi fi-${chosenCountry.toLowerCase()}`}
            ></span>
          )}
          value={chosenCountry}
          onChange={(e) => {
            const country = countriesCallingCodes.find(
              (_country) => _country.code === e.target.value
            );
            setChosenCountry(country.code);
            setValue(country.dial_code);
            if (setCountryCode) {
              setCountryCode(country.dial_code);
            }
          }}
        >
          {countriesCallingCodes.map((country) => (
            <MenuItem value={country.code} key={country.code}>
              <Stack direction={"row"} spacing={2}>
                <span
                  style={{
                    width: 35,
                    height: 25,
                    borderRadius: 5,
                  }}
                  className={`fi fi-${country.code.toLowerCase()}`}
                ></span>
                <Typography>{country.code}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>

        <Typography variant="subtitle1" color={"text.primary"}>
          +{value}
        </Typography>
      </Stack>
    </InputAdornment>
  );
};
