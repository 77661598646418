export const remoteJobTestQuestions = [
  {
    id: 1,
    question:
      "Vai Jūsu monitors ir pietiekami liels, ērti un pareizā attālumā novietots (60 ± 15 cm), grozāms, ar regulējamu augstumu, attēls nemirgo un neraustās?",
    info: [
      {
        text: `
      • Monitoram jābūt pietiekami lielam (atbilstoši darba uzdevumiem),
              bez tehniskiem defektiem un ar pietiekami kvalitatīvu attēlu (bez
              mirgošanas un attēla raustīšanās).
      `,
      },
      {
        text: `
      • Monitoram ir jābūt viegli pagriežamam, noliecamam, ar iespēju
              regulēt tā augstumu, skata leņķi un attālumu līdz acīm
              (ieteicamais attālums ir 60 ± 15 cm).
      `,
      },
      {
        text: `
      •  Monitora
augšējai malai jāatrodas acu augstumā vai nedaudz
zemāk. Ekrānam jāatrodas tieši pret darbinieku. Vēlams,
lai būtu iespējams regulēt attēla spilgtumu un kontrastu.
      `,
      },
    ],
  },
  {
    id: 2,
    question: "Vai Jūsu logiem ir gaismu atstarojošas žalūzijas vai aizkari?",
    info: [
      {
        text: `
      • Gaismai caur logiem jāplūst tā, lai neradītu atspīdumus datora
      monitorā vai uz citām virsmām. Ja tā notiek, ieteicams logus
      nodrošināt ar gaismu atstarojošām žalūzijām vai aizkariem.
      Žalūzijas noderēs arī gadījumā, ja tiešā saules gaisma logā ir
      pārāk spilgta.
      `,
      },
    ],
  },
  {
    id: 3,
    question:
      "Vai Jūsu darbavieta ir pietiekami plaša un ērta, kustības neierobežojoša?",
    info: [
      {
        text: `
        Darba telpai jābūt pietiekami plašai, lai tajā varētu
ievietot atbilstoša izmēra galdu, krēslu un būtu
ērti pārvietoties un kustēties (Latvijā nav noteiktas
minimālās prasības telpas lielumam).
      `,
      },
      {
        text: `
      Svarīgi, lai pie darba galda būtu iespējams sēdēt tieši
      pret monitoru un darba zona būtu pietiekami liela, lai
      tajā varētu novietot visu nepieciešamo.
      `,
        severity: "info",
      },
    ],
  },
  {
    id: 4,
    question:
      "Vai Jūsu darba telpā ir atbilstoša temperatūra un pietiekams gaisa mitrums?",
    info: [
      {
        text: `
      Darba telpā jānodrošina darba slodzei atbilstošs mikroklimats -
      temperatūra, gaisa relatīvais mitrums un gaisa apmaiņas ātrums.
      Veicot darbu ar datoru, šie parametri ir šādi: `,
      },
      { text: `• temperatūra vasarā 20-28 °C, ziemā 19-25 °C;` },
      { text: `• gaisa relatīvais mitrums 30-70 %;` },
      { text: `• gaisa kustības ātrums 0,05-0,15 m/sek.` },
      {
        text: `Ja telpā nav vispārējās ventilācijas sistēmas un piespiedu gaisa
      apmaiņas, jāveic regulāra telpu vēdināšana`,
        severity: "info",
      },
    ],
  },
  {
    id: 5,
    question:
      "Ja izmantojat portatīvo datoru, vai tas ir iespējami ērti novietots un pieslēgta pilna izmēra tastatūra un monitors?",
    info: [
      {
        text: `
      Ja darbam mājās ilgstoši izmantojat portatīvo datoru, jāatceras, ka
      tā lietošana var radīt papildu riskus - īpaši, ja tas ir ļoti kompakts
      (ekrāna izmērs 14” vai mazāks), ja ekrāns ir neliels, pieaug acu un
      plecu / kakla sasprindzinājuma risks. Arī mazāka tastatūra rada
      piespiedu darba pozu risku.
      `,
      },
      {
        text: `
      Ja izmantojat portatīvo datoru ilgstoši (vairāk nekā divas stundas
        dienā), ieteicams tam pieslēgt lielāka izmēra monitoru, tastatūru
        un peli (piemēram, izmantojot t. s. “paplašinājuma bāzi”
        vai “dokstaciju”).
      `,
      },
    ],
  },
  {
    id: 6,
    question:
      "Vai Jūsu tastatūra ir matēta, ar skaidri redzamiem simboliem, novietota monitora priekšā? Vai pele ir ērti lietojama?",
    info: [
      {
        text: `
      Tastatūrai jābūt ērti lietojamai un pietiekami lielai, matētai,
      ar skaidri saprotamiem simboliem, tai jābūt stabilai un viegli
      pārvietojamai pa galdu (ar pietiekami garu vadu).
      `,
      },
      {
        text: `
      Tastatūras priekšā jābūt pietiekamai vietai roku atbalstam
      (vismaz 10 cm). Ieteicams izmantot tastatūru, kas novietota
      nelielā leņķī pret galda virsmu, un tai jābūt iespējami plānai.
      Pelei jābūt iespējami lielai, novietotai tuvu tastatūrai un ērti
      pārvietojamai. Ieteicams izvēlēties t. s. vertikālās peles vai
      pirms izvēles izmēģināt vairākus modeļus.
      `,
      },
    ],
  },
  {
    id: 7,
    question:
      "Vai Jūsu galds ir pietiekami liels (minim. 800 x 1200 mm) un augsts (minim. 720 mm), noapaļotiem stūriem un stabils, zem tā ir pietiekami daudz vietas?",
    info: [
      {
        text: `
      Galdam ir jābūt pietiekami lielam (minimālais izmērs
ir 800 x 1200 mm, ieteicamais izmērs ir 1000 x 1600
mm), galda augstumam jābūt ne zemākam kā 720 mm,
ieteicams izmantot galdus ar regulējamu augstumu vai
t. s. “stāvgaldus”, pie kuriem iespējams gan sēdēt, gan
stāvēt.
      `,
      },
      {
        text: `
      Galdam vajadzētu būt ar noapaļotiem stūriem un
stabilam, zem tā jābūt pietiekami daudz vietas kājām.
      `,
      },
    ],
  },
  {
    id: 8,
    question:
      "Vai Jūsu darbavietā ir pieejams papildu lokālais apgaismojums, tas nežilbina un nerada atspīdumus?",
    info: [
      {
        text: `
      Nepieciešamības gadījumā darbavieta jāaprīko ar
lokālo (vietējo) apgaismojumu, vēlams - ar regulējamu
intensitāti. Svarīgi, lai tas neradītu atspīdumus un
nežilbinātu, lai gaisma nebūtu pārāk spilgta un neradītu
kontrastu.
      `,
      },
    ],
  },
  {
    id: 9,
    question:
      "Vai telpā ir pietiekams apgaismojums, tas ir tehniskā kārtībā (deg visas spuldzes, to gaisma nemirgo u. tml.)?",
    info: [
      {
        text: `
      Nepietiek ar telpu dabīgo apgaismojumu, ir jānodrošina
arī pietiekami spēcīgs vispārējais (mākslīgais)
apgaismojums. Veicot lasīšanas, drukāšanas un datu
apstrādes darbus, apgaismojuma intensitātei jābūt
aptuveni 500 lx.
      `,
      },
      {
        text: `
      Gaismas avotiem ir jāatrodas ārpus redzeslauka,
tie nedrīkst žilbināt un radīt atspīdumus monitorā.
Apgaismojumam jābūt tehniskā kārtībā (jādeg visām
spuldzēm, to gaisma nedrīkst mirgot vai raustīties),
gaismas ķermeņi regulāri jānotīra.
      `,
      },
    ],
  },
  {
    id: 10,
    question:
      "Vai Jūsu darba krēsls ir piemērots darbam ar datoru - tas ir stabils, grozāms, ar regulējamu augstumu un dziļumu, arī muguras balsta un roku balstu augstums ir regulējams?",
    info: [
      {
        text: `
      Krēslam ir jābūt stabilam, viegli grozāmam ap savu
      asi un pārvietojamam (ieteicams ar pieciem riteņiem).
      Jāizvēlas tāds krēsls, kam var regulēt vismaz sēdekļa
      un atzveltnes augstumu, sēdekļa dziļumu un slīpumu.
      Tāpat tam jābūt ar regulējamiem roku balstiem
      (augstumu un vēlams arī platumu).
      `,
      },
      {
        text: `
      Atzveltnei un sēdeklim ir jābūt polsterētiem un
pārklātiem ar neslīdīgu un gaisa caurlaidīgu materiālu,
kas neelektrizējas.
      `,
      },
    ],
  },
  {
    id: 11,
    question:
      "Vai Jūsu darbavietas grīda ir līdzena, bez pakāpieniem, sliekšņiem, nepareizi novietotiem vadiem vai citiem bīstamiem priekšmetiem?",
    info: [
      {
        text: `
      Visbiežāk nelaimes gadījumi mājas darbavietās ir
      saistīti ar paklupšanu un pakrišanu. Tādēļ grīdām
      jābūt līdzenām, tās nedrīkst būt slidenas; vadiem,
      kabeļiem un citiem priekšmetiem jābūt novietotiem un
      sakārtotiem tā, lai tie neradītu paklupšanas risku.
      Uzmanība jāpievērš arī sliekšņiem, pakāpieniem un
      citām potenciāli bīstamām vietām.
      `,
        severity: "warning",
      },
    ],
  },
  {
    id: 12,
    question:
      "Vai Jums ir nodrošināta darbam piemērota, aktuāla programmatūra, kas ļauj kvalitatīvi veikt darbu?",
    info: [
      {
        text: `
      Ja veicat attālināto darbu ar personīgo datoru,
svarīgi to nodrošināt ar darbam piemērotu, aktuālu
programmatūru, kas ļauj kvalitatīvi strādāt.
      `,
      },
      {
        text: `
      Svarīgi, lai arī ārpus biroja tiktu nodrošināta piekļuve
visiem nepieciešamajiem resursiem (serveriem,
datubāzēm u. tml.).
      `,
      },
    ],
  },
  {
    id: 13,
    question:
      "Vai Jūsu darba uzdevumus ir iespējams veikt piemērotā laikā, netraucējot ģimenes locekļus?",
    info: [
      {
        text: `
      Viens no būtiskākajiem “attālinātā darba” riskiem ir
saistīts ar nespēju darba uzdevumiem nepieciešamo
laiku nodalīt no privātajai dzīvei veltāmā laika. Tas
var radīt paaugstinātu psihoemocionālo spriedzi
un nelabvēlīgi ietekmēt ģimenes un privāto dzīvi.
      `,
      },
      {
        text: `
      Ieteicams darba pienākumiem veltāmo laiku iespējami
stingri nodalīt no privātās un ģimenes dzīves.
      `,
      },
    ],
  },
  {
    id: 14,
    question:
      "Vai Jūsu iekārtas, vadi, lampas u. tml. ir darba kārtībā - bez redzamiem defektiem?",
    info: [
      {
        text: `
      Jebkurai elektriskajai ierīcei (datoram, apgaismes iekārtām
        u.c.), ko lieto, ir jābūt darba kārtībā. Pārliecinieties, ka tās
        ieslēdzot vai lietojot, nav novērojami darbības traucējumi
        (dūmošana, dzirksteļošana u. tml.).
      `,
      },
      {
        text: `
      Šādos gadījumos nekavējoties pārtrauciet darbu un
      vērsies pēc palīdzības!
      `,
        severity: "critical",
      },
    ],
  },
  {
    id: 15,
    question:
      "Vai komunikācija ar tiešo vadītāju un kolēģiem ir pietiekama un tehniski vienkārša?",
    info: [
      {
        text: `
      Veicot attālināto darbu, savstarpēja komunikācija ir
īpaši būtiska, tās trūkums var nelabvēlīgi ietekmēt
gan konkrēto darbinieku, gan darba kolektīvu kopumā.
Komunikācijai ar tiešo vadītāju un kolēģiem jābūt
nodrošinātai arī tehniskā līmenī (piemēram, izmantojot
Skype vai līdzīgas programmas).
      `,
      },
      {
        text: `
      Darba devējam ir jāsedz ar šādu komunikāciju saistītie
izdevumi.
      `,
        severity: "info",
      },
    ],
  },

  {
    id: 16,
    question:
      "Vai attālinātajā darbā veicamie uzdevumi ir skaidri noformulēti un saprotami, paveicami noteiktajā laikā?",
    info: [
      {
        text: `
      Attālinātajā darbā ir īpaši būtiski, lai darba uzdevumi
būtu skaidri noformulēti un saprotami.
      `,
      },
      {
        text: `
      Svarīgi, lai tie
būtu paveicami noteiktā darba laika ietvaros, kā arī
būtu nepārprotami formulētas prasības par darba
pārskatiem un termiņiem.
      `,
      },
    ],
  },
];
