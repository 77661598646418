import { remoteJobTestQuestions } from "src/jsons/remote_job_questions";
import { tokens } from "../tokens";

export const lv = {
  [tokens.common.languageChanged]: "Valoda samainīta",
  [tokens.nav.documents]: "Mans uzņēmums",
  [tokens.nav.myDocuments]: "Mani uzdevumi",
  [tokens.nav.occupations]: "Amati",
  [tokens.nav.branches]: "Struktūrvienības",
  [tokens.nav.instructions]: "Instrukcijas",
  [tokens.nav.userInstructions]: "Instruktāžas",
  [tokens.nav.orders]: "Krātuve",
  [tokens.nav.risks]: "Riski",
  [tokens.nav.medicalCertificates]: "Reģistri",
  [tokens.nav.protocols]: "Uzraudzība",
  [tokens.nav.protocolAnswers]: "Aktu izpilde",
  [tokens.nav.plans]: "DA plāni",
  [tokens.nav.academy]: "Cerera academy",
  [tokens.nav.lessons]: "Apmācības",
  [tokens.nav.documentation]: "Cerera lietošanas pamācība",
  [tokens.nav.account]: "Konts",
  [tokens.nav.calendar]: "Kalendārs",
  [tokens.nav.menuOverhead]: "Cerera",
  [tokens.nav.course]: "Kurss",
  [tokens.nav.users]: "Darbinieki",
  [tokens.nav.administration]: "Administrēšana",
  [tokens.nav.fileManager]: "Failu pārskats",
  [tokens.nav.overview]: "Galvenā",
  [tokens.nav.profile]: "Profils",
  [tokens.nav.signatureJournals]: "Instruktāžu žurnāli",
  [tokens.nav.partnerCompanies]: "Sadarbības uzņēmumi",
  [tokens.nav.partnerDocuments]: "Iepazīstināšanas dokumenti",
  [tokens.nav.remoteJobTest]: "Pašnovērtējums",
  [tokens.nav.userSignedFiles]: "Dokumenti",
  [tokens.nav.settings]: "Pilnvarojumi",

  [tokens.adminNav.documentation]: "Lietošanas pamācība",
  [tokens.adminNav.administration]: "Administrācija",
  [tokens.adminNav.companies]: "Uzņēmumi",
  [tokens.adminNav.administrators]: "Administratori",
  [tokens.adminNav.settings]: "Iestatījumi",

  [tokens.remoteJob.test]: remoteJobTestQuestions,

  [tokens.remoteJob.start.title]: "Attālinātā darba pašnovērtējums",

  [tokens.remoteJob.edit.title]: "Attālinātā darba pašnovērtējums",
  [tokens.remoteJob.edit.addPictures]: "Pievienot darba vietas bildes",
  [tokens.remoteJob.edit.pictures.primary]: "Skats no aizmugures",
  [tokens.remoteJob.edit.pictures.side]: "Sānskats",

  [tokens.remoteJob.result.title]: "Attālinātā darba pašnovērtējums",
  [tokens.remoteJob.result.answers]: "Jautājumi ar atbildi jā",
  [tokens.remoteJob.result.missing]: "Jautājumi ar atbildi nē",
  [tokens.remoteJob.result.addedPictures]: "Pievienotie attēli",

  [tokens.hints.yes]: "Jā",
  [tokens.hints.no]: "Nē",
  [tokens.hints.question]: "Jautājums",
  [tokens.hints.more]: "Vairāk",

  [tokens.buttons.save]: "Saglabāt",
  [tokens.buttons.edit]: "Labot",
  [tokens.buttons.cancel]: "Atcelt",
  [tokens.buttons.ready]: "Gatavs",
  [tokens.buttons.start]: "Sākt",
  [tokens.buttons.delete]: "Dzēst",
  [tokens.buttons.download]: "Lejupielādēt",
  [tokens.buttons.previous]: "Atpakaļ",
  [tokens.buttons.next]: "Tālāk",
  [tokens.buttons.finish]: "Iesniegt",

  [tokens.generic.greeting]: "Sveicināti",
  [tokens.generic.learningGreeting]: "Laiks mācībām",
  [tokens.generic.learningSuccess]: "Super visi uzdevumi izpildīti!",
  [tokens.generic.expressionGreat]: "Super",
  [tokens.generic.learningNoTasks]: "Šobrīd nav uzdevumu",

  [tokens.instructions.signJournal]: "Parakstīt žurnālu",
  [tokens.instructions.eJournalAvailable]:
    "Jums ir pieejami elektroniski parakstāmi žurnāli",
  [tokens.instructions.signableDocumentHint]:
    "Jūsu parakstu žurnāls tiek sagatavots, jums tiks ziņots tiklīdz tas būs pieejams. Šobrīd nav nepieciešams veikt citas darbības",

  [tokens.academy.startTest]: "Veikt zināšanu pārbaudi",
  [tokens.academy.endTest]: "Pabeigt testu",
  [tokens.academy.testDone]: "Apmācība nokārtota",
  [tokens.academy.markTestAsRead]: "Iepazinos",
  [tokens.academy.readDocument]: "Turpināt",
  [tokens.academy.riskAssesmentRead]: "Ar riska novērtējumu iepazinos",
  [tokens.academy.instructionName]: "Instruktāžas nosaukums",
  [tokens.academy.courseName]: "Apmācības nosaukums",
  [tokens.academy.progress]: "Izpildījums",
  [tokens.academy.actions]: "Darbības",
  [tokens.academy.progressIncomplete]: "Nav veikts",
  [tokens.academy.readMaterial]: "Sākt apmācību",
  [tokens.academy.riskAssesment]: "Riska novērtējums",
  [tokens.academy.lesson]: "Apmācība",
  [tokens.academy.document]: "Dokumenti",
  [tokens.academy.description]: "Apraksts",
  [tokens.academy.question]: "Jautājums",
  [tokens.academy.questions]: "Jautājumi",
  [tokens.academy.from]: "no",
  [tokens.academy.imageZoomHint]: "Noklikšķiniet uz attēlu, lai to palielinātu",
  [tokens.academy.answerSelectHint]: "Izvēlaties vienu no atbilžu variantiem",
  [tokens.academy.zoomIconHint]: "Palielināt",
  [tokens.academy.answered]: "Atbildēts",
  [tokens.academy.notAnswered]: "Nav atbildēts",

  [tokens.results.incorrectAnswers]: "Nepareizās atbildes",
  [tokens.results.titleSuccess]: "Pārbaude nokārtota",
  [tokens.results.titleFail]: "Pārbaude nav nokārtota",
  [tokens.results.goToMainPage]: "Pāriet uz galveno lapu",
  [tokens.results.goToNextTest]: "Pāriet uz nākamo apmācību",
  [tokens.results.retakeTest]: "Pildīt atkārtoti",
  [tokens.results.result]: "Rezultāts",
  [tokens.results.answer]: "Atbilde",

  [tokens.partnerDocuments.noDocumentsToRead]:
    "Šobrīd nav jālasa vai jāpievieno dokumenti",
  [tokens.partnerDocuments.readingExplanation]:
    "Dokumenti ar kuriem nepieciešams iepazīties",
  [tokens.partnerDocuments.uploadingExplanation]:
    "Dokumenti kuri ir jāiesniedz",
  [tokens.partnerDocuments.startReading]: "Iepazīties",
  [tokens.partnerDocuments.documentRead]: "Iepazīstināts ar dokumentu",
  [tokens.partnerDocuments.documentName]: "Dokumenta nosaukums",
  [tokens.partnerDocuments.documentReadStatus]: "Izlasīts",
  [tokens.partnerDocuments.documentNotReadStatus]: "Nav lasīts",
  [tokens.partnerDocuments.filesAdded]: "Ir pievienoti faili",
  [tokens.partnerDocuments.filesNotAdded]: "Nav pievienoti faili",
  [tokens.partnerDocuments.description]: "Apraksts",
  [tokens.partnerDocuments.addedDocuments]: "Pievienotie dokumenti",
  [tokens.partnerDocuments.addFile]: "Pievienot failu",

  [tokens.documentsToSign.noDocumentsToSign]:
    "Šobrīd nav nepieciešams parakstīt dokumentus",
  [tokens.documentsToSign.explanationOne]:
    "Šeit ir pieejami dokumenti, kurus jums ir nepieciešams aizpildīt un parakstīt, vai tikai parakstīt",
  [tokens.documentsToSign.explanationTwo]:
    "Lejupielādējiet dokumentu no saraksta, veiciet tā sagatavošanu un parakstīšanu (fiziski parakstīts un ieskanēts vai parakstīts elektroniski), un augšupielādējiet parakstīto dokumentu",
  [tokens.documentsToSign.workSafetyJournal]: "Darba aizsardzības žurnāls",
  [tokens.documentsToSign.fireSafetyJournal]: "Ugunsdrošības žurnāls",
  [tokens.documentsToSign.introductoryJournal]: "Ievadapmācības žurnāls",
  [tokens.documentsToSign.uploadSignedFile]: "Augšupielādēt parakstītu failu",
  [tokens.documentsToSign.signed]: "Parakstīts",
  [tokens.documentsToSign.unsigned]: "Nav parakstīts",
};
