import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DefaultLayout } from "src/layouts/default-layout";

const IndexPage = lazy(() => import("src/pages/signed_doc_verification/index"));
const VerificationPage = lazy(() =>
  import("src/pages/signed_doc_verification/verification/verification")
);

export const signedDocumentVerification = [
  {
    path: "sign-api",
    element: (
      <DefaultLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DefaultLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "document-validation",
        element: <VerificationPage />,
      },
    ],
  },
];
