import { useRoutes } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RTL } from "./components/rtl";
import { Toaster } from "./components/toaster";
import { routes } from "./routes";
import { store } from "./store";
import { createTheme } from "./theme";
// Remove if react-quill is not used
import "react-quill/dist/quill.snow.css";
// Remove if react-draft-wysiwyg is not used
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Remove if simplebar is not used
import "simplebar-react/dist/simplebar.min.css";
// Remove if mapbox is not used
import "mapbox-gl/dist/mapbox-gl.css";
// Remove if locales are not used
import "./locales/i18n";
import userData from "src/contexts/user-data-context";

import { useState } from "react";

import { lv } from "date-fns/locale";
import { AlertProvider, useAlerts } from "./hooks/use-alerts";
import { Alerts } from "./pages/components/alerts";
import { useEffect } from "react";
import { ErrorStrip } from "./sections/error-strip/error-strip";

export const App = () => {
  const [data, setData] = useState(undefined);

  const element = useRoutes(routes);

  const theme = createTheme({
    colorPreset: "amber",
    contrast: "normal",
    direction: "ltr",
    paletteMode: "light",
    responsiveFontSizes: true,
  });

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        localeText={{
          nextMonth: "Nākamais mēnesis",
          previousMonth: "Iepriekšējais mēnesis",
        }}
        adapterLocale={lv}
      >
        <ThemeProvider theme={theme}>
          <userData.Provider value={{ data: data, setData: setData }}>
            <AlertProvider>
              <Helmet>
                <meta name="color-scheme" content={theme.paletteMode} />
                <meta name="theme-color" content={theme.palette.neutral[900]} />
              </Helmet>
              <RTL direction={theme.direction}>
                <CssBaseline />
                <Alerts />
                <ErrorStrip />
                {element}
                <Toaster />
              </RTL>
            </AlertProvider>
          </userData.Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
};
