import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import userData from "src/contexts/user-data-context";
import { useContext } from "react";
import { paths } from "src/paths";
import { useNav } from "./useNav";

const VerticalLayoutRoot = styled("div")(({ theme, navWidth }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: navWidth,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const nav = useNav();

  const { data } = useContext(userData);
  const user = data.user;

  console.log(data);

  if (!data) {
    window.location.href = paths[404];
    return "";
  }

  // if (!data?.company && data?.companies?.length > 1) {
  //   window.location.href = paths.dashboard.chooseCompany;
  // }

  console.log(user.role_profile);

  return (
    <>
      <TopNav
        mobileNavOpen={lgUp ? nav.open : mobileNav.open}
        onMobileNavClose={lgUp ? nav.handleClose : mobileNav.handleClose}
        onMobileNavOpen={lgUp ? nav.handleOpen : mobileNav.handleOpen}
        canSeeDocuments={
          user.role_profile.documents.canSeeAllDocuments.value ||
          user.is_cerera_admin == 1
        }
        canEdit={
          user.role_profile.documents.canEditDocuments.value ||
          user.is_cerera_admin == 1
        }
      />

      {lgUp && (
        <SideNav
          onClose={nav.handleClose}
          open={nav.open}
          color={navColor}
          sections={sections}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot navWidth={nav.open ? 280 : 0}>
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
