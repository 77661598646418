import Bell01Icon from "@untitled-ui/icons-react/build/esm/Bell01";
import {
  Badge,
  CircularProgress,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { NotificationsPopover } from "./notifications-popover";
import { useContext, useMemo } from "react";
import userData from "src/contexts/user-data-context";

export const NotificationsButton = () => {
  const popover = usePopover();
  const { data } = useContext(userData);

  const notifications = useMemo(() => data?.notifications, [data]);

  return (
    <>
      <Tooltip title="Paziņojumi">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <Badge color="error" badgeContent={notifications?.count ?? ""}>
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
