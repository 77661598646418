import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from "@mui/material";

export const Notification = ({avatar, header, action, url}) => {
    return (
      <ListItem
        divider
        sx={{
          alignItems: "flex-start",
          "&:hover": {
            backgroundColor: "action.hover",
          },
          "& .MuiListItemSecondaryAction-root": {
            top: "24%",
          },
        }}
      >
        <ListItemAvatar sx={{ mt: 0.5 }}>
          <Avatar>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Typography sx={{ mr: 0.5 }} variant="subtitle2">
                {header}
              </Typography>
              <Stack direction={"row"}>
                <Typography sx={{ mr: 0.5 }} variant="body2">
                  Nepieciešams veikt:
                  <Link
                    href={url}
                    underline="always"
                    variant="body2"
                    color={"text.primary"}
                    marginLeft={1}
                  >
                    {action}
                  </Link>
                </Typography>
              </Stack>
            </Box>
          }
        />
      </ListItem>
    );
}