import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { paths } from "src/paths";

export const useSections = ({ sections }) => {
  const { t } = useTranslation();
  const { company_id, documentation_id } = useParams();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: "Sākums",
            path: window.location.pathname.includes("company")
              ? paths.documentation.companyDocumentations.index
                  .replace(":company_id", company_id)
                  .replace(":documentation_id", documentation_id)
              : paths.documentation.index,
          },
          ...sections,
        ],
      },
    ];
  }, [t, sections]);
};
