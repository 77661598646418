import { Stack, Typography } from "@mui/material";
import { BulletPoint } from "./home-hero-text-bullet";

const bulletPoints = ["VADĪBA", "APMĀCĪBA", "UZRAUDZĪBA"];

export const HeroText = () => {
  return (
    <Stack height={240}>
      <Typography
        textAlign={"right"}
        color="text.secondary"
        fontWeight={"900"}
        fontSize="2.5rem"
        sx={{ mb: 2, "font-family": "'Plus Jakarta Sans',sans-serif" }}
      >
        DARBA AIZSARDZĪBAS
      </Typography>
      <Stack direction={"column"} alignItems={"center"} spacing={2}>
        {bulletPoints.map((point, index) => (
          <BulletPoint key={point} name={point} index={index} />
        ))}
      </Stack>
    </Stack>
  );
};
