import axios from "axios";
import { useEffect, useState } from "react";
import { apiRoutes } from "src/api/api-routes/routes";

export const ErrorStrip = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    axios.get(apiRoutes.serverStatus).catch((err) => setActive(true));
  }, []);

  return (
    active && (
      <div
        style={{
          zIndex: 9999,
          backgroundColor: "red",
          color: "white",
          width: "100vw",
          height: 50,
          textAlign: "center",
        }}
      >
        <h2 style={{ margin: "auto" }}>
          Platforma Cerera šobrīd nav pieejama! Atvainojamies par sagādatajām
          neērtībām.
        </h2>
      </div>
    )
  );
};
