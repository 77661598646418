import { Box, Stack, Typography } from "@mui/material";
import { sendVerificationCode } from "src/api/auth/login";
import PhoneInput from "src/components/phone_input/phone_input";
import { useAlerts } from "src/hooks/use-alerts";
import { HeroStripe } from "src/sections/home/hero-stripe";

export const PhoneLoginField = ({
  setStatus,
  statuses,
  phone,
  setPhone,
  countryCode,
  setCountryCode,
}) => {
  // const { createAlert } = useAlerts();

  const onSubmit = async () => {
    setStatus(statuses.loading);

    await sendVerificationCode(phone, countryCode)
      .then((res) => {
        setStatus(statuses.awaitingVerification);
      })
      .catch((err) => {
        setStatus(statuses.phoneLogin);
        // createAlert("Lietotājs ar šādu tālruni neeksistē");
      });
  };

  const checkIfEnterPressed = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Stack
      direction={"row"}
      spacing={3}
      sx={{
        transform: "skewX(30deg)",
      }}
    >
      <Stack
        sx={{
          width: 270,
          height: 71,
          bgcolor: "white",
          margin: "auto",
        }}
      >
        <Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              width: 270,
              height: 71,
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ transform: "skewX(-30deg)", margin: "0px 20px" }}>
              <PhoneInput
                onKeyDown={checkIfEnterPressed}
                label=""
                fullWidth
                setPhone={setPhone}
                phone={phone}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                variant="standard"
                inputProps={{
                  style: {
                    fontSize: "1rem",
                    paddingBottom: 7,
                    color: "black",
                  },
                }}
              />

              <Stack
                direction="row"
                justifyContent={"space-between"}
                mt={1}
                mx={0}
                spacing={3}
                alignItems="center"
                alignContent={"center"}
              >
                <Typography color={"text.secondary"} variant={"caption"}>
                  Ievadiet jūsu tālruņa numuru
                </Typography>
                <Typography
                  variant={"caption"}
                  color={"text.secondary"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => {
                    setStatus(statuses.loginChoice);
                    setPhone("");
                  }}
                >
                  Atcelt
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <HeroStripe onClick={onSubmit} />
    </Stack>
  );
};
