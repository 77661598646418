import PropTypes from "prop-types";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { CompanyPopover } from "./company-popover";
import { useContext, useEffect, useState } from "react";
import userData from "src/contexts/user-data-context";

import { paths } from "src/paths";
import { useAlerts } from "src/hooks/use-alerts";
import axios from "axios";
import { apiHeaders, apiRoutes } from "src/api/api-routes/routes";
import {
  ArrowDownwardRounded,
  KeyboardArrowDownRounded,
} from "@mui/icons-material";
import { getCurrentDateTime } from "src/utils/date-manipulation";

export const CompanySwitch = (props) => {
  const { data } = useContext(userData);
  const { createAlert } = useAlerts();
  const [companies, setCompanies] = useState();

  const isAdmin = data.user.is_cerera_admin == 1;
  const company = data?.company?.company_data;
  const popover = usePopover();

  useEffect(() => {
    axios
      .get(
        apiRoutes.getWorkerRecentlyVisitedCompanies,
        apiHeaders({}, { workers: data.user.id })
      )
      .then((res) =>
        setCompanies(
          res.data[0].companies.filter((_c) => _c.company_id !== company.id)
        )
      )
      .catch((err) => createAlert("error", "Neizdevās iegūt uzņēmumu datus"));
  }, [data]);

  const onChange = async (id) => {
    await axios
      .post(
        apiRoutes.editWorkerCompanyLastVisited,
        JSON.stringify({
          user_id: data.user.id,
          company_id: id,
          date: getCurrentDateTime(),
        }),
        apiHeaders()
      )
      .catch((err) => console.log(err));

    sessionStorage.setItem("currentCompanyId", id);
    window.location.href = paths.dashboard.index;
  };

  const truncateString = (str) => {
    const max = 40;
    return str.length > max ? `${str.substring(0, max)}...` : str;
  };

  if (!companies || !company) {
    return <CircularProgress />;
  }

  return companies.length > 0 || isAdmin ? (
    <>
      <Stack {...props}>
        <Button
          fullWidth
          sx={{
            py: 1,
            px: 1.5,
            "&:hover": { backgroundColor: "rgba(108, 115, 127, .3)" },
            justifyContent: "space-between",
          }}
          color="inherit"
          endIcon={<KeyboardArrowDownRounded />}
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
            {truncateString(company.company_name)}
          </Typography>
        </Button>
      </Stack>
      <CompanyPopover
        isAdmin={isAdmin}
        anchorEl={popover.anchorRef.current}
        onChange={onChange}
        onClose={popover.handleClose}
        open={popover.open}
        companies={companies}
      />
    </>
  ) : (
    <Typography sx={{ fontSize: 14, fontWeight: "bold", py: 1, px: 1.5 }}>
      {truncateString(company.company_name)}
    </Typography>
  );
};

CompanySwitch.propTypes = {
  sx: PropTypes.object,
};
