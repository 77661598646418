import { Seo } from "src/components/seo";
import { Footer } from "src/layouts/marketing/footer";
import { HomeCta } from "src/sections/home/home-cta";
import { HomeFaqs } from "src/sections/home/home-faqs";
import { HomeFeatures } from "src/sections/home/home-features";
import { HomeHero } from "src/sections/home/home-hero";
import { HomeReviews } from "src/sections/home/home-reviews";

const Page = () => {
  return (
    <>
      <Seo />
      <main>
        <HomeHero />
        {/* <HomeCta />
        <HomeFaqs />
        <HomeFeatures />
        <HomeReviews />
        <Footer /> */}
      </main>
    </>
  );
};

export default Page;
