import PropTypes from "prop-types";
import { CircularProgress, Popover } from "@mui/material";
import { NotificationsList } from "./notifications-list";
import { useContext, useMemo } from "react";
import userData from "src/contexts/user-data-context";

export const NotificationsPopover = (props) => {
  const { anchorEl, onClose, open = false, ...other } = props;
  const { data } = useContext(userData);

  const notifications = useMemo(() => data?.notifications, [data]);

  if (!notifications) {
    return <CircularProgress />;
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}
    >
      <NotificationsList notifications={notifications} />
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array,
  onClose: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  onRemoveOne: PropTypes.func,
  open: PropTypes.bool,
};
