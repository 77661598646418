import { Box, Container, Stack, Typography } from "@mui/material";
import { Logo } from "src/components/logo";
import LoginPage from "src/pages/auth/phoneVersion/login";

export const HomeHeroPhone = () => {
  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        pt: "120px",
      }}
    >
      <Container maxWidth="100%">
        <Box>
          <Stack
            spacing={2}
            sx={{ position: "relative", maxWidth: 590, margin: "auto" }}
          >
            <Stack direction={"row"}>
              <Logo height={200} width={200} />
              <Stack
                justifyContent={"space-between"}
                sx={{ marginLeft: "-70px" }}
              >
                <div></div>
                <Box
                  sx={{
                    mt: 2,
                    transition: "0.3s",
                  }}
                >
                  <Typography
                    textAlign={"left"}
                    fontWeight={900}
                    fontSize={25}
                    color={"text.secondary"}
                    sx={{ fontFamily: "'Plus Jakarta Sans',sans-serif" }}
                  >
                    CERERA
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <LoginPage />
            </Stack>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};
