import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import userData from "src/contexts/user-data-context";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import { HelpOutline, Settings } from "@mui/icons-material";

export const useSections = () => {
  const { t } = useTranslation();
  const { data } = useContext(userData);

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.admin.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.menuOverhead),
        items: [
          {
            title: t(tokens.adminNav.administration),
            path: paths.admin.largeNavigation,
            icon: (
              <SvgIcon fontSize="small">
                <ApartmentRoundedIcon />
              </SvgIcon>
            ),

            items: [
              {
                title: t(tokens.adminNav.companies),
                path: paths.admin.companies.index,
              },
              {
                title: t(tokens.adminNav.administrators),
                path: paths.admin.administrators.index,
              },
            ],
          },
          {
            title: t(tokens.adminNav.settings),
            path: paths.admin.settings,
            icon: (
              <SvgIcon fontSize="small">
                <Settings />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.adminNav.documentation),
            path: paths.admin.documentation.index,
            icon: (
              <SvgIcon fontSize="small">
                <HelpOutline />
              </SvgIcon>
            ),
          },
        ],
      },
    ];
  }, [t, data]);
};
