import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { VerticalLayout } from "./vertical-layout";
import { SplashScreen } from "src/components/splash-screen";
import userData from "src/contexts/user-data-context";
import { getUserData, validateJWTToken } from "src/api/auth/login";
import { paths } from "src/paths";
import { getNotifications } from "src/sections/notifications";

export const Layout = (props) => {
  const settings = useSettings();
  const sections = useSections();

  const { data, setData } = useContext(userData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const currentCompanyId = sessionStorage.getItem("currentCompanyId");

    if (token && currentCompanyId) {
      setLoading(true);
      validateJWTToken()
        .then(async () => {
          await getUserData().then((res) => {
            console.log(res);
            setData((prevState) => ({
              user: res.data.user,
              company: res.data.company,
            }));
            setLoading(false);
          });
          await getNotifications().then((res) =>
            setData((prevState) => ({ ...prevState, notifications: res }))
          );
        })
        .catch((err) => (window.location.href = paths[401]));
    } else {
      window.location.href = paths[404];
    }
  }, []);

  console.log(data);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <VerticalLayout
          sections={sections}
          navColor={settings.navColor}
          {...props}
        />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
