import axios from "axios";
import { apiHeaders, apiRoutes } from "../api-routes/routes";

export const validateJWTToken = async () => {
  return await axios
    .get(apiRoutes.validateJWTToken, apiHeaders())
    .then((res) => true)
    .catch((res) => false);
};

export const sendVerificationCode = async (phone, countryCode) => {
  const payload = { phone: phone, country_code: countryCode };
  await axios.post(apiRoutes.login, JSON.stringify(payload), apiHeaders());
};

export const verifyUser = async (code, phone, countryCode, setData) => {
  const payload = {
    code: code,
    phone: phone,
    country_code: countryCode,
    loginType: "phone",
    password: "",
  };

  await axios
    .post(apiRoutes.verify, JSON.stringify(payload), apiHeaders())
    .then((res) => {
      setData({
        user: res.data.user,
        companies: res.data.companies,
        company: undefined,
      });
      sessionStorage.setItem("token", res.data.accessToken);
      sessionStorage.setItem("user_id", res.data.user.id);
      sessionStorage.setItem("firstTimeLogin", true);
    });
};

export const getBaseUserData = async (user_id) => {
  const payload = {
    id: user_id,
    company_id: undefined,
  };

  return await axios
    .post(apiRoutes.getUserBasics, JSON.stringify(payload), apiHeaders())
    .then((res) => res.data);
};

export const getUserData = async (company_id) => {
  if (!company_id) {
    company_id = sessionStorage.getItem("currentCompanyId");
  }
  const payload = {
    id: sessionStorage.getItem("user_id"),
    company_id: company_id,
  };

  return await axios
    .post(apiRoutes.getUser, JSON.stringify(payload), apiHeaders())
    .then((res) => res);
};
