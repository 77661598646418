import { useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Unstable_Grid2 as Grid,
  CircularProgress,
} from "@mui/material";
import { company_category, company_sizes } from "./components/utils";
import { useState } from "react";
import axios from "axios";
import { apiRoutes } from "src/api/api-routes/routes";
import { useAlerts } from "src/hooks/use-alerts";
import { CheckmarkIcon } from "react-hot-toast";

const statuses = {
  loading: "loading",
  sent: "sent",
  initial: "initial",
};

const isEmailSentRecently = () => {
  // make sure that email is not being sent more often than every 10 minutes
  try {
    const dateWhenSent = new Date(localStorage.getItem("home_email_sent"));
    const minuteLimit = 1 * 60000;

    return new Date() - dateWhenSent <= minuteLimit;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ContactForm = () => {
  const [size, setSize] = useState(company_sizes[0]);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState(
    isEmailSentRecently() ? statuses.sent : statuses.initial
  );

  const { createAlert } = useAlerts();

  const handleSubmit = (e) => {
    setStatus(statuses.loading);
    e.preventDefault();
    const target = e.target;

    const payload = {
      name: target.name.value,
      company: target.company.value,
      email: target.email.value,
      phone: target.phone.value,
      size: size,
      category: category,
      message: target.message.value,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(apiRoutes.sendHomeApplication, JSON.stringify(payload), {
        headers,
      })
      .then(() => {
        setStatus(statuses.sent);
        localStorage.setItem("home_email_sent", new Date());
      })
      .catch(() => {
        createAlert();
        setStatus(statuses.initial);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Vārds *
            </FormLabel>
            <OutlinedInput name="name" required />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Uzņēmuma Nosaukums *
            </FormLabel>
            <OutlinedInput name="company" required />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Saziņas E-pasts *
            </FormLabel>
            <OutlinedInput name="email" type="email" required />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Tālrunis *
            </FormLabel>
            <OutlinedInput name="phone" required type="tel" />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Darbinieku Skaits
            </FormLabel>
            <Select
              name="size"
              fullWidth
              value={size}
              onChange={(e) => setSize(e.target.value)}
            >
              {company_sizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Nozare
            </FormLabel>
            <Select
              name="category"
              fullWidth
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {company_category.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl fullWidth>
            <FormLabel
              sx={{
                color: "text.primary",
                mb: 1,
              }}
            >
              Ziņa
            </FormLabel>
            <OutlinedInput
              fullWidth
              name="message"
              required
              multiline
              rows={6}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}
      >
        <Button
          fullWidth
          size="large"
          disabled={status === statuses.sent || status === statuses.loading}
          variant={status === statuses.sent ? "text" : "contained"}
          type="submit"
          startIcon={status === statuses.sent ? <CheckmarkIcon /> : ""}
        >
          {status === statuses.initial ? (
            "Nosūtīt"
          ) : status === statuses.loading ? (
            <CircularProgress />
          ) : statuses.sent ? (
            "Paldies! Ziņa veiksmīgi nosūtīta"
          ) : (
            ""
          )}
        </Button>
      </Box>
      <Typography color="text.secondary" sx={{ mt: 3 }} variant="body2">
        Nosūtot šo ziņu, Jūs piekrītat mūsu{" "}
        <Link
          color="text.primary"
          href="#"
          underline="always"
          variant="subtitle2"
        >
          Privātuma politikai
        </Link>
        .
      </Typography>
    </form>
  );
};
