import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

export const HeroStripe = ({ onClick, disabled, small }) => {
  return (
    <Stack
      onClick={onClick}
      justifyContent={"center"}
      alignItems="center"
      sx={{
        width: small ? "100%" : 200,
        height: 71,
        backgroundColor: "#909295",
        transform: "skewX(0deg)",
        transition: "0.5s",
        "&:hover": {
          backgroundColor: !disabled ? "text.secondary" : "#909295",
          cursor: !disabled ? "pointer" : "inherit",
        },
      }}
    >
      {!disabled && (
        <Box sx={{ transform: "skewX(-30deg)" }}>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="button" color={"white"}>
              Pieslēgties
            </Typography>
            <KeyboardDoubleArrowRightOutlinedIcon sx={{ color: "white" }} />
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
