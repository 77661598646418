import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { lv } from "./translations/lv";
import { en } from "./translations/en";

const defaultLang = localStorage.getItem("defaultLang");

i18n.use(initReactI18next).init({
  resources: {
    lv: { translation: lv },
    en: { translation: en },
  },
  lng: defaultLang,
  fallbackLng: "lv",
  interpolation: {
    escapeValue: false,
  },
});
