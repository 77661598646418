export const company_sizes = ["1-10", "11-30", "31-50", "50-100", "100+"];

export const company_category = [
  "Lauksaimniecība",
  "Mežsaimniecība",
  "Zivsaimniecība",
  "Rūpniecība",
  "Elektroenerģija",
  "Siltumapgāde",
  "Ūdens apgāde",
  "Atkritumu apsaimniekošana",
  "Būvniecība",
  "Tirdzniecība",
  "Transports",
  "Ēdināšanas pakalpojumi",
  "Informācijas un tehnoloģijas",
  "Finanšu pakalpojumu",
  "Izglītības pakalpojumi",
  "Veselības pakalpojumi",
  "Māksla, izklaide, atpūta",
  "Cits",
];
