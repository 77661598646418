import { Stack } from "@mui/material";
import { useState } from "react";
import { PhoneLoginField } from "./phone-login-field";
import { TemporaryPasswordLogin } from "./temporary-password-login";
import { LoadingLogin } from "./loading";
import { CodeVerification } from "./code-verification/code-verification";

const statuses = {
  loading: "loading",
  awaitingVerification: "awaitingVerification",
  login: "login",
  password: "password",
};

function LoginPage({}) {
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("371");
  const [status, setStatus] = useState(statuses.login);

  return (
    <>
      <Stack>
        {status == statuses.loading ? (
          <LoadingLogin />
        ) : status == statuses.awaitingVerification ? (
          <CodeVerification
            countryCode={countryCode}
            setStatus={setStatus}
            statuses={statuses}
            phone={phone}
            setPhone={setPhone}
          />
        ) : status === statuses.password ? (
          <TemporaryPasswordLogin
            setStatus={(status) => {
              setStatus(status);
            }}
            statuses={statuses}
          />
        ) : (
          <PhoneLoginField
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setStatus={(status) => {
              setStatus(status);
            }}
            statuses={statuses}
            phone={phone}
            setPhone={setPhone}
          />
        )}
      </Stack>
    </>
  );
}

export default LoginPage;
