import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as ChooseCompanyLayout } from "src/layouts/chooseCompany";

const IndexPage = lazy(() => import("src/pages/chooseCompany/index"));

export const chooseCompanyRoutes = [
  {
    path: "choose-company",
    element: (
      <ChooseCompanyLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </ChooseCompanyLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
    ],
  },
];
